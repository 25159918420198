import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthLayout } from '@vyce/core/src/views/auth/AuthLayout';
import config from '@vyce/core/src/assets/config';

import { LegendLogin } from './Login';

export const Auth: React.FC = () => {
  return (
    <AuthLayout rightPictureSrc={config.auth.generic}>
      <Switch>
        <Route exact path="/auth" render={() => <Redirect to="/auth/login" />} />
        <Route path="/auth/login" component={LegendLogin} />
      </Switch>
    </AuthLayout>
  );
};
