import React, { useContext } from 'react';

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppKeyboardDatePicker } from '@vyce/core/src/components/inputs/AppKeyboardDatePicker';
import { AppKeyboardTimePicker } from '@vyce/core/src/components/inputs/AppKeyboardTimePicker';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

interface Props {
  open: boolean;
  setOpen: Function;
  sendSMS: (scheduled: string) => void;
}

interface Form {
  date: Date;
  time: Date;
}

export const ScheduleMessageDialog: React.FC<Props> = ({ open, setOpen, sendSMS }) => {
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<Form>({
    defaultValues: {
      date: new Date(),
      time: new Date(),
    },
  });
  const { control, reset, watch } = methods;
  const date = watch('date');
  const time = watch('time');

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = ({ date, time }: Form) => {
    const dateWithoutTime = new Date(date.setHours(0, 0, 0, 0));
    const combinedDate = dateWithoutTime.setHours(time.getHours(), time.getMinutes(), 0, 0);
    sendSMS(dayjs(combinedDate)?.format());
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Schedule the message</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <Typography variant="subtitle1" color="textSecondary">
              When should the message be sent?
            </Typography>
            <Box display="flex" marginTop={2}>
              <Box marginRight={1} width="50%">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="date"
                    control={control}
                    render={props => (
                      <AppKeyboardDatePicker
                        value={props.field.value}
                        onChange={data => props.field.onChange(data)}
                        label="Date"
                        format="dd/MM/yyyy"
                        error={undefined}
                        helperText={''}
                        fullWidth
                        disablePast
                        disableToolbar
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Box>

              <Box marginLeft={1} width="50%">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="time"
                    control={control}
                    render={props => (
                      <AppKeyboardTimePicker
                        value={props.field.value}
                        onChange={data => props.field.onChange(data)}
                        label="Time"
                        fullWidth
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="large" style={{ width: 106 }} onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={!date || !time}
              size="large"
              color="primary"
              style={{ width: 106 }}
              type="submit"
              variant="contained">
              Schedule
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
