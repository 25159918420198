import React, { useContext } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { PhoneNumberField } from '@vyce/core/src/components/controlled-inputs/PhoneNumberField';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { useTypedSelector } from '../../../hooks/useTypedSelector';

interface Props {
  open: boolean;
  setOpen: Function;
  sentToMe: (data: SendSMSToMeData) => void;
}

export interface SendSMSToMeData {
  phone: string;
}

export const SendSMSToMeDialog: React.FC<Props> = ({ open, setOpen, sentToMe }) => {
  const { isMobile } = useContext(DeviceContext);
  const { user } = useTypedSelector(state => state);
  const methods = useForm<SendSMSToMeData>({
    defaultValues: {
      phone: user.phone,
    },
  });
  const { reset } = methods;

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(sentToMe)}>
          <DialogTitle>Send To Me</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <Typography variant="subtitle1" color="textSecondary">
              Would you like to change your phone number?
            </Typography>
            <PhoneNumberField
              rules={{ required: 'Phone number is required' }}
              name="phone"
              label="Phone Number"
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button size="large" style={{ width: 106 }} onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button size="large" color="primary" style={{ width: 106 }} type="submit" variant="contained">
              Send
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
