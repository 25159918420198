import { useContext } from 'react';
import { TeamsPageContext } from '@vyce/core/src/contexts';

import { useTypedSelector } from '../../../hooks';

export const useWidgetData = () => {
  const { access_token } = useTypedSelector(state => state.helper);
  const { teams, selectedCompanyId = '' } = useContext(TeamsPageContext);

  return { access_token, teams: teams ?? [], selectedCompanyId };
};
