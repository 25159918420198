import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { CommonNavBarBlock } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { TabItem } from '@vyce/core/src/types';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { PreviewButton } from '@vyce/core/src/views/preview/components/PreviewButton';

import { RouteLegendUserProfileComponent } from './RouteLegendUserProfileComponent';

export const PROFILE_TABS: TabItem[] = [
  {
    label: 'Personal',
    link: 'personal',
  },
  {
    label: 'Professional',
    link: 'professional',
  },
  {
    label: 'Employment',
    link: 'employment',
  },
  {
    label: 'ID & RTW',
    link: 'documents',
  },
  {
    label: 'Qualifications',
    link: 'qualifications',
  },
  {
    label: 'Medical',
    link: 'medical',
  },
  {
    label: 'Payments',
    link: 'payments',
  },
  {
    label: 'Timesheets',
    link: 'timesheets',
  },
  {
    label: 'Agreements',
    link: 'agreements',
  },
  {
    label: 'Roles & Permissions',
    link: 'roles',
  },
];

export const LegendUserProfile: React.FC = () => {
  const { isMobile, isLargeDesktop } = useContext(DeviceContext);
  const history = useHistory();
  const padding = getPaddingForContent(isMobile);
  const [tabs, setTabs] = useState(PROFILE_TABS);

  const PREVIEW_TAB = useMemo(() => {
    const urlItemsArr = history.location.pathname.split('/');
    urlItemsArr.pop();
    const newUrl = urlItemsArr.join('/');
    return {
      label: 'Preview',
      link: newUrl,
    };
  }, [history.location.pathname]);

  const goToPreview = () => {
    history.push(PREVIEW_TAB.link);
  };

  useEffect(() => {
    if (isLargeDesktop && tabs.includes(PREVIEW_TAB)) {
      setTabs(value => value.slice(0, tabs.length - 1));
    }
    if (!isLargeDesktop) {
      setTabs(value => [...value, PREVIEW_TAB]);
    }
  }, [isLargeDesktop]);

  return (
    <Box position="relative" height="calc(100% - 64px)">
      <CommonNavBarBlock tabItems={tabs} />

      <Box height="100%">
        {isLargeDesktop && <PreviewButton goToPreview={goToPreview} />}

        <Box padding={padding} height="100%">
          <Switch>
            <Route component={RouteLegendUserProfileComponent} />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};
