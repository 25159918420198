import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
    container: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px',
      maxHeight: 'calc(100vh - 440px)',
      flex: 1,
      overflow: 'auto',
    },
    textarea: {
      '& .MuiInputBase-input': {
        color: theme.palette.text.secondary,
        fontSize: '15px',
        minHeight: '120px',
      },
    },
    menuList: {
      padding: 0,
    },
  })
);
