import React from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineTrash, HiLink } from 'react-icons/hi';
import { BsCheck } from 'react-icons/bs';
import { Box, Button, useTheme } from '@material-ui/core';

import {
  AppDataGrid,
  FilterSystem,
  AdaptiveSearch,
  AppIconButton,
  AppChip,
} from '@vyce/core/src/components/';

import useStyles from './styles';
import { columns, filtersSections } from './config';
import { useTagsData } from './hooks';
import { CreateTagDialog, MergeTagsDialog } from './components';

const chipStyle = { height: 'auto' };

export const Tags = () => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    rows,
    loading,
    searchText,
    selectionModel,
    mergeIsAvailable,
    verifyIsAvailable,
    isCreateDialogOpen,
    handleSearch,
    groups,
    isMergeDialogOpen,
    filteredTags,
    handleMergeTags,
    handleCreateNewTag,
    deleteSelectedTags,
    verifySelectedTags,
    handleFiltersChange,
    handleCellEditCommit,
    setSelectionModel,
    setCreateDialogOpen,
    setCreateDialogClose,
    setMergeDialogOpen,
    setMergeDialogClose,
    handleClearSelectedTags,
  } = useTagsData();

  return (
    <>
      <Box className={classes.moduleContainer}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" gridGap={16}>
            <AdaptiveSearch
              variant="paper"
              searchText={searchText || ''}
              handleSearchChange={handleSearch}
            />
            {selectionModel.length !== 0 && (
              <AppChip
                style={chipStyle}
                label={`${selectionModel.length} tag${selectionModel.length > 1 ? 's' : ''} selected`}
                onDelete={handleClearSelectedTags}
              />
            )}
          </Box>

          <Box display="flex" gridGap={16}>
            {!!selectionModel?.length && (
              <Box display="flex" alignItems="center" gridGap={16}>
                {mergeIsAvailable && (
                  <AppIconButton
                    variant="success"
                    style={{
                      backgroundColor: theme.palette.type === 'dark' ? '#50C8FC' : '#E3FEFF',
                      width: '40px',
                      height: '40px',
                    }}
                    onClick={setMergeDialogOpen}>
                    <HiLink size="20px" color="#005BD4" />
                  </AppIconButton>
                )}
                {verifyIsAvailable && (
                  <AppIconButton variant="success" onClick={verifySelectedTags}>
                    <BsCheck size="20px" color="#2e7d32" />
                  </AppIconButton>
                )}
                <AppIconButton variant="error" onClick={deleteSelectedTags}>
                  <HiOutlineTrash size="20px" />
                </AppIconButton>
              </Box>
            )}

            <Button
              startIcon={<AiOutlinePlus />}
              variant="contained"
              onClick={setCreateDialogOpen}
              size="small"
              fullWidth
              color="primary">
              Create
            </Button>

            <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFiltersChange} />
          </Box>
        </Box>

        <AppDataGrid
          noPaper
          rows={rows}
          getRowId={row => row.uuid}
          onCellEditCommit={params => handleCellEditCommit(params)}
          checkboxSelection
          height="calc(100vh - 180px)"
          selectionModel={selectionModel}
          onSelectionModelChange={ids => setSelectionModel(ids)}
          columns={columns}
          loading={loading}
          disableSelectionOnClick
        />
      </Box>

      {isCreateDialogOpen && (
        <CreateTagDialog
          handleCreateNewTag={handleCreateNewTag}
          open={isCreateDialogOpen}
          handleClose={setCreateDialogClose}
          groups={groups}
          loading={loading}
        />
      )}

      {isMergeDialogOpen && (
        <MergeTagsDialog
          tags={filteredTags}
          loading={loading}
          open={isMergeDialogOpen}
          handleClose={setMergeDialogClose}
          handleMergeTags={handleMergeTags}
        />
      )}
    </>
  );
};
