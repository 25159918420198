import React from 'react';

import {
  createTimesheetLineRequest,
  deleteTimesheetLineRequest,
  editTimesheetLineRequest,
  generateNextPayrunRequest,
  getTimesheetAsFileRequest,
  getTimesheetMappingRequest,
  getTimesheetRequest,
  importTimesheetRequest,
  lookupEmployeesRequest,
  reopenTimesheetRequest,
  submitTimesheetRequest,
} from '@vyce/core/src/api/legend/pay';
import { TimesheetPage } from '@vyce/core/src/views/payroll/components/TimesheetPage';

import { useTypedSelector } from '../../hooks';

export const LegendTimesheetPage: React.FC = () => {
  const { access_token } = useTypedSelector(state => state.helper);

  return (
    <TimesheetPage
      // TODO implement legend functionality
      locations={[]}
      token={access_token}
      lookupEmployeesRequest={lookupEmployeesRequest}
      getTimesheetAsFileRequest={getTimesheetAsFileRequest}
      createTimesheetLineRequest={createTimesheetLineRequest}
      editTimesheetLineRequest={editTimesheetLineRequest}
      getTimesheetRequest={getTimesheetRequest}
      submitTimesheetRequest={submitTimesheetRequest}
      reopenTimesheetRequest={reopenTimesheetRequest}
      deleteTimesheetLineRequest={deleteTimesheetLineRequest}
      importTimesheetRequest={importTimesheetRequest}
      getTimesheetMappingRequest={getTimesheetMappingRequest}
      duplicateTimesheetRequest={generateNextPayrunRequest}
    />
  );
};
