import React, { useContext } from 'react';

import { Box, Button } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';

import { AppDataGrid, AppLink } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { DeviceContext } from '@vyce/core/src/contexts';

import { useGeneralGridData } from './hooks';
import { ModuleProps } from './types';
import { commonColumns } from './config';

const columns: GridColDef[] = [
  {
    field: 'first_name',
    headerName: 'Name',
    flex: 0.2,
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: params => (
      <Box display="flex" width="100%">
        <AppLink to={`users/${params.row?.first_name}_${params.row?.uuid}`}>
          {`${params.row.first_name} ${params.row.last_name}`}
        </AppLink>
      </Box>
    ),
  },
  {
    field: 'ni_number',
    headerName: 'Ref #',
    flex: 0.1,
    width: 95,
    disableColumnMenu: true,
  },
  ...commonColumns,
];

export const GeneralGrid = ({ inviteButton, ...restProps }: ModuleProps) => {
  const horizontalScrollClasses = useHorizontalScrollStyles();
  const { isMobile } = useContext(DeviceContext);
  const {
    roles,
    loading,
    sortModel,
    total,
    pageSize,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
    handleInviteButtonClick,
  } = useGeneralGridData(restProps);

  return (
    <>
      <Box
        mb={2}
        gridGap={16}
        className={clsx(horizontalScrollClasses.blockWrapper, horizontalScrollClasses.blockWithHideScroll)}>
        <Box>
          <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          {inviteButton ? (
            React.createElement(inviteButton)
          ) : (
            <Button
              onClick={handleInviteButtonClick}
              size="small"
              data-tour="invite"
              startIcon={<AiOutlinePlus />}
              variant="contained"
              color="primary">
              Invite New Members
            </Button>
          )}
        </Box>
      </Box>

      <AppDataGrid
        rows={roles}
        noPaper
        height="calc(100vh - 250px)"
        getRowId={row => row.uuid}
        columns={columns}
        loading={loading}
        pageSize={pageSize || GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        rowCount={total}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        withProfileButton
      />
    </>
  );
};
