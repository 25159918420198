import React, { SyntheticEvent } from 'react';

import { CompanyFilter } from '@vyce/core/src/components';
import { Company } from '@vyce/core/src/types';

import { useActions, useTypedSelector } from '../../../hooks';

export const LegendCompanyFilter: React.FC = () => {
  const { companies, selectedCompany } = useTypedSelector(state => state.helper);
  const { setSelectedCompany } = useActions();

  const handleCompanyChange = (_e: SyntheticEvent, company: Company) => {
    setSelectedCompany(company);
  };

  return (
    <CompanyFilter
      defaultValue={selectedCompany}
      companies={companies || []}
      onCompanyChange={handleCompanyChange}
    />
  );
};
