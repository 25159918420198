import { useContext, useEffect, useState } from 'react';

import { getSitesRequest } from '@vyce/core/src/api/time';
import { Site, TimeAndAttendanceLocation } from '@vyce/core/src/types';
import { siteToLocation } from '@vyce/core/src/modules/timeModule/utils';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../../hooks';

const defaultFilterValues = {
  limit: 500,
  order_by: [],
};

export const useModuleData = () => {
  const [locations, setLocations] = useState<TimeAndAttendanceLocation[]>([]);

  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { handleServerError } = useContext(NotificationContext);

  const getLocations = async () => {
    if (!selectedCompany?.uuid) {
      return;
    }
    try {
      const res = await getSitesRequest(selectedCompany.uuid, defaultFilterValues);
      const locations = res.data.items?.map((item: Site) => siteToLocation(item));
      setLocations(locations);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getLocations();
  }, [selectedCompany?.uuid]);

  return {
    selectedCompanyId: selectedCompany?.uuid,
    locations,
  };
};
