import React from 'react';

import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';

import { DeviceProvider } from '@vyce/core/src/contexts';
import { AppSnackbarProvider } from '@vyce/core/src/components';
import { useAppTheme } from '@vyce/core/src/hooks/useAppTheme';
import { useDeviceData } from '@vyce/core/src/hooks/useDeviceData';
import NotificationProvider from '@vyce/core/src/contexts/notificationContext';

import { store } from './store';
import { Main } from './views/main/Main';

const App = () => {
  const { changeColorTheme, themeType, colorTheme, theme } = useAppTheme();
  const { isMobile, isDesktop, isLargeDesktop, iOS } = useDeviceData();

  return (
    <div id="app">
      <Provider store={store}>
        <DeviceProvider value={{ isMobile, isDesktop, isLargeDesktop, iOS }}>
          <Router
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}>
            <NotificationProvider>
              <AppSnackbarProvider>
                <Main
                  themeType={themeType}
                  theme={theme}
                  changeColorTheme={changeColorTheme}
                  colorTheme={colorTheme}
                />
              </AppSnackbarProvider>
            </NotificationProvider>
          </Router>
        </DeviceProvider>
      </Provider>
    </div>
  );
};

export default App;
