import React from 'react';

import { Hiring } from '@vyce/core/src/views/hiring/Hiring';

import { useTypedSelector } from '../../hooks';
import { LegendCompanyFilter } from '../main/components/LegendCompanyFilter';

export const LegendHiring: React.FC = () => {
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);
  const { first_name, last_name, phone, email } = useTypedSelector(state => state.user);

  return (
    <Hiring
      token={access_token}
      isJobPosted={true}
      companyId={selectedCompany?.uuid}
      contactName={`${first_name} ${last_name}`}
      contactPhone={phone}
      contactEmail={email}>
      <LegendCompanyFilter />
    </Hiring>
  );
};
