import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { GetCompanyTeamsDataRequest } from '@vyce/core/src/api/types';
import { getCompanyTeamsRequest } from '@vyce/core/src/api/legend/teams';
import { LIST_STEP } from '@vyce/core/src/constants';
import { Team } from '@vyce/core/src/types';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../../hooks';
import { LegendInviteButton } from '../../../components';
import { DEFAULT_TEAMS_LIST_SORTING } from '../config';

export const useTeamsData = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [teams, setTeams] = useState<Team[]>([]);
  const [teamsCount, setTeamsCount] = useState(0);

  const { access_token, companies, selectedCompany } = useTypedSelector(state => state.helper);
  const { first_name } = useTypedSelector(state => state.user);
  const { handleServerError } = useContext(NotificationContext);
  const { pathname } = useLocation();

  const firstIdOfTeam = useMemo(() => {
    const team = (teams.length && teams[0]) || ({} as Team);
    if (!(team && team.uuid && team.name)) return '';
    return `${team.name}_${team.uuid}`;
  }, [teams]);
  const selectedCompanyId = selectedCompany?.uuid ?? '';

  const getTeams = useCallback(
    async (offset = 0, substring = '', sorting = DEFAULT_TEAMS_LIST_SORTING) => {
      if (!selectedCompanyId) {
        return setLoading(false);
      }

      setLoading(true);
      const requestData: GetCompanyTeamsDataRequest = {
        offset,
        limit: LIST_STEP,
        substring,
        order_by: [sorting],
      };
      try {
        const {
          data: { items, count },
        } = await getCompanyTeamsRequest({
          companyId: selectedCompanyId,
          requestData,
        });
        setTeams(items);
        setTeamsCount(count);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        handleServerError(e);
      }
    },
    [access_token, selectedCompanyId]
  );

  useEffect(() => {
    getTeams();
  }, [selectedCompanyId, pathname]);

  const contextValue = useMemo(
    () => ({
      loading,
      teams,
      teamsCount,
      selectedCompanyId,
      selectedCompanyIsSubscribed: selectedCompany?.subscribed_company,
      inviteButton: LegendInviteButton,
      getTeams,
    }),
    [loading, teams, teamsCount, getTeams]
  );

  return {
    loading,
    contextValue,
    teams,
    companies,
    teamsCount,
    firstIdOfTeam,
    first_name,
    getTeams,
  };
};
