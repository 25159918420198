import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import { DeviceContext, SubscriptionProvider } from '@vyce/core/src/contexts';
import { CommonNavBarBlock, PageLoading } from '@vyce/core/src/components';
import { SubscriptionInfo } from '@vyce/core/src/views/subscription/components/SubscriptionInfo';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { SUBSCRIPTION_TABS } from '@vyce/core/src/modules/subscriptionModule/constants';
import { useSubscriptionContext } from '@vyce/core/src/modules/subscriptionModule/hooks/useSubscriptionContext';

import { useSubscriptionData } from '../../modules/subscriptionModule/hooks/useSubscriptionData';
import { LegendCompanyFilter } from '../main/components/LegendCompanyFilter';
import { EmployerSubscriptionPayments } from './LegendSubscriptionPayments';
import { LegendBillingInfo } from './LegendBillingInfo';
import { LegendEditBillingContact } from './LegendEditBillingContact';
import { LegendEditCardDetails } from './LegendEditCardDetails';
import { useStyles } from './styles';

export const LegendSubscriptionAndBilling: React.FC = () => {
  const classes = useStyles();
  const { loading, selectedCompany, subscription, billingData } = useSubscriptionData();
  const { isMobile } = useContext(DeviceContext);
  const padding = getPaddingForContent(isMobile);

  const { contextValue, backTo } = useSubscriptionContext();

  return (
    <Box height="100%">
      <Box className={classes.moduleContainer}>
        <CommonNavBarBlock tabItems={SUBSCRIPTION_TABS} backTo={backTo}>
          <LegendCompanyFilter />
        </CommonNavBarBlock>

        <Box padding={padding} display="flex" flexDirection="column" className={classes.container}>
          {loading ? (
            <PageLoading />
          ) : (
            <SubscriptionProvider value={contextValue}>
              <Switch>
                <Route
                  path={'/subscriptions/payments'}
                  component={() => <EmployerSubscriptionPayments subscription={subscription} />}
                />
                <Route path={'/subscriptions/billing/contact'} component={LegendEditBillingContact} />
                <Route path={'/subscriptions/billing/payment-details'} component={LegendEditCardDetails} />
                <Route path={'/subscriptions/billing'} component={LegendBillingInfo} />
                <Route
                  path={'/subscriptions'}
                  component={() => (
                    <SubscriptionInfo
                      companyName={selectedCompany?.name}
                      subscription={subscription}
                      billingData={billingData}
                    />
                  )}
                />
              </Switch>
            </SubscriptionProvider>
          )}
        </Box>
      </Box>
    </Box>
  );
};
