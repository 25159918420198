import React from 'react';

import { TeamsDashboard } from '@vyce/core/src/modules';
import { TeamsDashboardModuleProvider } from '@vyce/core/src/contexts';

import { useTeamsDashboardData } from './hooks';

export const TeamsDashboardModule = () => {
  const { contextOptions } = useTeamsDashboardData();

  return (
    <TeamsDashboardModuleProvider value={contextOptions}>
      <TeamsDashboard />
    </TeamsDashboardModuleProvider>
  );
};
