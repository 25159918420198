import React, { useContext } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { useTypedSelector } from '../../../hooks/useTypedSelector';

interface Props {
  open: boolean;
  setOpen: Function;
  sentToMe: (data: SendToMeData) => void;
}

export interface SendToMeData {
  email: string;
}

export const SendEmailToMeDialog: React.FC<Props> = ({ open, setOpen, sentToMe }) => {
  const { isMobile } = useContext(DeviceContext);
  const { user } = useTypedSelector(state => state);
  const methods = useForm<SendToMeData>({
    defaultValues: {
      email: user.email,
    },
  });
  const {
    control,
    formState: { errors },
    reset,
  } = methods;

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(sentToMe)}>
          <DialogTitle>Send To Me</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <Typography variant="subtitle1" color="textSecondary">
              Would you like to change your email?
            </Typography>
            <Controller
              control={control}
              rules={{
                required: 'Email is required',
              }}
              name="email"
              render={({ field }) => (
                <AppTextField
                  label="Email"
                  margin="normal"
                  fullWidth
                  {...field}
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message || ''}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button size="large" style={{ width: 106 }} onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button size="large" color="primary" style={{ width: 106 }} type="submit" variant="contained">
              Send
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
