import React, { useMemo } from 'react';

import { Teams } from '@vyce/core/src/views/teams';
import { TeamListModuleProvider } from '@vyce/core/src/contexts';

import { useCreateTeam, useTeamsListData } from './hooks';

const Wrapper = ({ children }: { children: React.ReactElement }) => {
  const createTeamHookData = useCreateTeam();
  const teamsListHookData = useTeamsListData();

  const value = useMemo(
    () => ({
      ...teamsListHookData,
      ...createTeamHookData,
    }),
    [createTeamHookData, teamsListHookData]
  );

  return <TeamListModuleProvider value={value}>{children}</TeamListModuleProvider>;
};

export const TeamsListModule = () => (
  <Wrapper>
    <Teams />
  </Wrapper>
);

export const GetStartedModule = (props: { hasPermissions?: boolean }) => (
  <Wrapper>
    <Teams.GetStarted {...props} />
  </Wrapper>
);
