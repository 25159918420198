import React from 'react';

import {
  createOrUpdateCompanyBankAccountRequest,
  getCompanyBankAccountRequest,
  getPayScheduleRequest,
  updatePaySchedulesRequest,
} from '@vyce/core/src/api/legend/pay';
import { PaySchedulePage } from '@vyce/core/src/views/payroll/PaySchedulePage';

export const LegendPaySchedulePage: React.FC = () => {
  return (
    <PaySchedulePage
      isLegend={true}
      createOrUpdateCompanyBankAccountRequest={createOrUpdateCompanyBankAccountRequest}
      getCompanyBankAccountRequest={getCompanyBankAccountRequest}
      getPayScheduleRequest={getPayScheduleRequest}
      updatePaySchedulesRequest={updatePaySchedulesRequest}
    />
  );
};
