import React, { useContext } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { createEmailTemplateRequest } from '@vyce/core/src/api/legend/notifications';
import { EmailTemplate } from '@vyce/core/src/types';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../../hooks/useTypedSelector';

interface Props {
  open: boolean;
  setOpen: Function;
  callback: Function;
}

export const CreateEmailTemplateDialog: React.FC<Props> = ({ open, setOpen, callback }) => {
  const { isMobile } = useContext(DeviceContext);
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { access_token } = useTypedSelector(state => state.helper);
  const methods = useForm<EmailTemplate>({
    defaultValues: {
      name: '',
      template_id: '',
    },
  });
  const {
    control,
    formState: { errors },
    reset,
  } = methods;

  const onSubmit = async (data: EmailTemplate) => {
    try {
      await createEmailTemplateRequest({ token: access_token, data });
      showNotification({ message: 'Template has been created.', options: { variant: 'success' } });
      callback();
      handleClose();
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Add a new template</DialogTitle>
          <DialogContent>
            <Controller
              control={control}
              rules={{
                required: 'Name is required',
              }}
              name="name"
              render={({ field }) => (
                <AppTextField
                  label="Name"
                  margin="normal"
                  fullWidth
                  {...field}
                  error={!!errors?.name?.message}
                  helperText={errors?.name?.message || ''}
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: 'Template ID is required',
              }}
              name="template_id"
              render={({ field }) => (
                <AppTextField
                  label="SendGrid Template ID"
                  margin="normal"
                  style={{ marginBottom: 16 }}
                  fullWidth
                  {...field}
                  error={!!errors?.template_id?.message}
                  helperText={errors?.template_id?.message || ''}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button size="large" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button size="large" color="primary" type="submit" variant="contained">
              Create
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
