import { useTable } from '@vyce/core/src/hooks/useTable';

export const useCommonModuleData = () => {
  const {
    sortModel,
    offset,
    substring,
    total,
    setTotal,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({});

  return {
    total,
    offset,
    substring,
    sortModel,
    setTotal,
    handlePageChange,
    handleSortModelChange,
    handleSearchChange,
  };
};
