import React from 'react';

import { LocationOnboarding } from '@vyce/core/src/views/time/LocationOnboarding';

import { useTypedSelector } from '../../hooks';

export const LegendLocationOnboarding: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return <LocationOnboarding selectedCompany={selectedCompany} firstName="Legend" />;
};
