import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';

import { Box, MenuItem, MenuList } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid';

import {
  deleteTimesheetRequest,
  generateNextPayrunRequest,
  getNextPayPeriodRequest,
  getPaySchedulesRequest,
  getTimesheetsRequest,
} from '@vyce/core/src/api/legend/pay';
import { currencyFormatter, isNil } from '@vyce/core/src/utils';
import { FilterSection, PaySchedule, Timesheet } from '@vyce/core/src/types';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import {
  GridActions,
  ConfirmDialog,
  AppLink,
  FilterSystem,
  AppDataGridWithSavedPage,
} from '@vyce/core/src/components';
import { formatTableDate } from '@vyce/core/src/utils/dates';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { PayModuleContext } from '@vyce/core/src/contexts/payrollModule';
import { CreateNewTimesheet, StatusComponents } from '@vyce/core/src/views/payroll/components';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useDebounceValue } from '@vyce/core/src/hooks/useDebounceValue';
import { useTable } from '@vyce/core/src/hooks/useTable';

const defaultSortModel: GridSortModel = [{ field: 'created', sort: 'desc' }];

export const LegendTimesheets: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
  const [filters, setFilters] = useState<any>();
  const [companyId, setCompanyId] = useState<string>();
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const [paySchedules, setPaySchedules] = useState<PaySchedule[]>([]);
  const [openCreateTimesheetDialog, setOpenCreateTimesheetDialog] = useState<boolean>(false);
  const { selectedCompanyId, companies } = useContext(PayModuleContext);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [timesheetToDelete, setTimesheetToDelete] = useState<Timesheet>();

  const { handleServerError, showNotification } = useContext(NotificationContext);
  const history = useHistory();
  const { sortModel, offset, total, setTotal, setOffset, handleSortModelChange, handlePageChange } =
    useTable({ defaultSortModel });

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const enrichedTimesheets = useMemo(() => timesheets.map((item, i) => ({ ...item, id: i })), [timesheets]);

  const isAvailableToDelete = (pay_schedule_id: string) => {
    const payScheduleTimesheets = timesheets.filter(
      timesheet => timesheet.pay_schedule_id === pay_schedule_id
    );
    return payScheduleTimesheets?.length > 1;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      minWidth: 140,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppLink
            to={`/payroll/timesheets/${params.row?.name}_${params.row?.pay_run_id}_${params.row?.company?.company_id}`}>
            {params.row?.name}
          </AppLink>
        </Box>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      valueGetter: params => params.row?.company?.name,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      minWidth: 120,
    },
    {
      field: 'week',
      headerName: 'Week',
      disableColumnMenu: true,
      width: 90,
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'status',
      headerName: 'Status',
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <StatusComponents status={params.row.status} isGreen={params.row.status === 'submitted'} />
      ),
      width: 100,
    },
    {
      field: 'submitted_by',
      headerName: 'Submitted By',
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row: { submitted_by } }) => (
        <Box display="flex" width="100%">
          <AppLink
            target="_blank"
            to={`/user-management/users/${submitted_by.first_name}_${submitted_by.uuid}`}>
            {submitted_by.first_name} {submitted_by.last_name}
          </AppLink>
        </Box>
      ),
      minWidth: 120,
    },
    {
      field: 'modified',
      headerName: 'Modified',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: params => formatTableDate(params.row?.modified, true),
      minWidth: 120,
    },
    {
      field: 'created',
      headerName: 'Created',
      valueGetter: params => formatTableDate(params.row?.created, true),
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'workers',
      headerName: 'Workers',
      disableColumnMenu: true,
      width: 110,
    },
    {
      field: 'gross',
      headerName: 'Gross',
      valueFormatter: params => currencyFormatter.format(params.row.gross),
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: true,
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <Box display="flex" width="100%">
            <GridActions close={closeGridAction}>
              <MenuList>
                <MenuItem onClick={() => generateNextPayrun(params.row.pay_schedule_id, true)}>
                  Duplicate
                </MenuItem>
                {params.row.status !== 'submitted' && (
                  <MenuItem
                    onClick={() => {
                      setOpenConfirmDialog(true);
                      setTimesheetToDelete(params.row as Timesheet);
                    }}>
                    Delete timesheet
                  </MenuItem>
                )}
              </MenuList>
            </GridActions>
          </Box>
        );
      },
    },
  ];

  const filtersSections: FilterSection[] = [
    {
      title: 'Timesheet',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Status',
          field: 'pay_run_status',
          values: ['', 'draft', 'submitted'],
          defaultValue: '',
        },
        {
          type: 'checkbox',
          label: 'Outsourced',
          field: 'outsourced',
          values: false,
          defaultValue: false,
        },
      ],
    },
  ];

  const getTimesheets = useCallback(async () => {
    if (isNil(dOffset)) return;

    setLoading(true);
    const payload = {
      limit: GRID_PAGE_SIZE,
      offset: dOffset as number,
      order_by: formatSortModel<Timesheet>(sortModel),
      company_id: selectedCompanyId || undefined,
      pay_run_status: filters?.pay_run_status || undefined,
      outsourced: filters?.outsourced || undefined,
    };
    try {
      const res = await getTimesheetsRequest(payload);
      setTimesheets(res.data.items);
      setTotal(res.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [dOffset, filters, sortModel, selectedCompanyId]);

  const deleteTimesheet = async (payrunId: string, companyId: string, payScheduleId: string) => {
    if (!isAvailableToDelete(payScheduleId)) {
      return showNotification({
        message: 'You cannot delete last timesheet in Pay schedule',
        options: { variant: 'warning' },
      });
    }
    try {
      const res = await deleteTimesheetRequest(companyId, payrunId);
      resetCloseStatus();
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      setTimesheetToDelete(undefined);
      getTimesheets();
      setOpenConfirmDialog(false);
    } catch (e) {
      resetCloseStatus();
      handleServerError(e);
    }
  };

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
  };

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const getNextPayPeriod = async (payScheduleId: string) => {
    try {
      const res = await getNextPayPeriodRequest(payScheduleId);
      return res.data;
    } catch (e) {
      handleServerError(e);
      return '';
    }
  };

  const generateNextPayrun = async (payScheduleId: string, copyPrevious: boolean, isRedirect?: boolean) => {
    if (!payScheduleId) {
      return;
    }
    try {
      setCloseGridAction(true);
      const res = await generateNextPayrunRequest({ payScheduleId, copyPrevious });
      const newTimesheet = res.data.timesheet;
      getTimesheets();
      setCloseGridAction(false);
      showNotification({ message: 'Timesheet has been created', options: { variant: 'success' } });
      setOpenCreateTimesheetDialog(false);
      if (isRedirect) {
        history.push(
          `/payroll/timesheets/${newTimesheet?.pay_schedule?.name}_${newTimesheet?.pay_run_id}_${companyId}`
        );
      }
    } catch (e) {
      setCloseGridAction(false);
      handleServerError(e);
    }
  };

  const handleCompanyChange = async (companyId: string) => {
    if (!companyId) {
      return setCompanyId(undefined);
    }
    try {
      const res = await getPaySchedulesRequest({ companyId, limit: 1000 });
      setPaySchedules(res.data.items);
      setCompanyId(companyId);
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleDialogClose = () => {
    setOpenCreateTimesheetDialog(false);
    setTimeout(() => {
      setPaySchedules([]);
      setCompanyId(undefined);
    }, 200);
  };

  useEffect(() => {
    getTimesheets();
  }, [getTimesheets]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Box marginRight={2}>
          <CreateNewTimesheet
            selectedCompanyId={companyId}
            open={openCreateTimesheetDialog}
            setOpen={setOpenCreateTimesheetDialog}
            handleDialogClose={handleDialogClose}
            generateNextPayrun={generateNextPayrun}
            handleCompanyChange={handleCompanyChange}
            paySchedules={paySchedules}
            companies={companies}
            getNextPayPeriod={getNextPayPeriod}
          />
        </Box>

        <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={enrichedTimesheets}
        loading={loading}
        columns={columns}
        rowCount={total}
        height="calc(100vh - 240px)"
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="timesheets"
        setOffset={setOffset}
      />

      {timesheetToDelete && (
        <ConfirmDialog
          handleClose={() => {
            setOpenConfirmDialog(false);
            setTimesheetToDelete(undefined);
          }}
          open={openConfirmDialog}
          confirmText="Yes, delete timesheet"
          cancelText="No, go back"
          title="Are you sure you want to delete this timesheet?"
          handleConfirm={() =>
            deleteTimesheet(
              timesheetToDelete.pay_run_id,
              timesheetToDelete.company?.company_id as string,
              timesheetToDelete.pay_schedule_id
            )
          }
        />
      )}
    </>
  );
};
