import { useContext, useEffect, useMemo, useState } from 'react';

import { GridCellEditCommitParams, GridCellValue, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';
import escapeRegExp from 'lodash/escapeRegExp';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';

import {
  createTagRequest,
  deleteTagRequest,
  editTagRequest,
  fetchTagsRequest,
  getTagsGroups,
  postMergeTags,
} from '@vyce/core/src/api/legend/handbook';
import { Tag } from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks';

import { CreateTagFromVales } from '../types';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

export const useTagsData = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [rows, setRows] = useState<Tag[]>([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState<boolean>(true);
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [isCreateDialogOpen, setCreateDialogOpen, setCreateDialogClose] = useBooleanState(false);
  const [isMergeDialogOpen, setMergeDialogOpen, setMergeDialogClose] = useBooleanState(false);
  const [groups, setGroups] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const filteredTags = useMemo(
    () => tags.filter(tag => selectionModel.includes(tag.uuid)),
    [tags, selectionModel]
  );
  const mergeIsAvailable = selectionModel.length <= 1 ? false : uniqBy(filteredTags, 'group').length === 1;
  const verifyIsAvailable = filteredTags.some(item => !item.verified);

  const getTags = async () => {
    try {
      const res = await fetchTagsRequest({ short: false, limit: 2000 });
      const tags = res.data.items;
      setTags(tags);
      setRows(tags);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleServerError(error);
    }
  };

  const fetchTagsGroup = async () => {
    try {
      const { data } = await getTagsGroups();
      setGroups(data.groups);
    } catch (error) {
      handleServerError(error);
    }
  };

  const handleCreateNewTag = async (formData: CreateTagFromVales) => {
    try {
      setLoading(true);
      const result = await createTagRequest({
        name: formData.name,
        group: formData.group,
      });
      handleTagCreate(result.data as Tag);
      setCreateDialogClose();
      setLoading(false);
    } catch (error) {
      setCreateDialogClose();
      handleServerError(error);
      setLoading(false);
    }
  };

  const handleMergeTags = async (data: { main_tag_id: string; tags: string[] }) => {
    try {
      setLoading(true);
      await postMergeTags(data);
      setMergeDialogClose();
      setLoading(false);
      setSelectionModel([]);
      getTags();
    } catch (error) {
      handleServerError(error);
      setLoading(false);
    }
  };

  const handleClearSelectedTags = () => {
    setSelectionModel([]);
  };

  const updateTag = async (newTag: Tag) => {
    try {
      await editTagRequest({
        id: newTag.uuid,
        name: newTag.name,
        group: newTag.group as string,
        verified: !!newTag.verified,
      });
      setTags(tags =>
        tags.map(tag => {
          if (tag.uuid === newTag.uuid) {
            return {
              ...tag,
              name: newTag.name,
              verified: !!newTag.verified,
            };
          }
          return tag;
        })
      );
      setRows(rows =>
        rows.map(row => {
          if (row.uuid === newTag.uuid) {
            return {
              ...row,
              name: newTag.name,
              verified: !!newTag.verified,
            };
          }
          return row;
        })
      );
      showNotification({ message: 'Successfully updated', options: { variant: 'success' } });
    } catch (error) {
      handleServerError(error);
    }
  };

  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    const tag = findTag(params.id);
    const newName: GridCellValue = params.value;
    if (tag && newName && tag.name !== newName) {
      updateTag({ ...tag, name: newName as string });
    }
  };

  const clearDeletedTags = (tags: Tag[]): Tag[] => {
    return tags.filter(tag => !selectionModel.includes(tag.uuid));
  };

  const deleteSelectedTags = () => {
    try {
      selectionModel.forEach(async item => {
        await deleteTagRequest({ id: item });
      });
      setTags(tags => clearDeletedTags(tags));
      setRows(tags => clearDeletedTags(tags));
    } catch (error) {
      handleServerError(error);
    }
  };

  const verifySelectedTags = () => {
    try {
      selectionModel.forEach(item => {
        const tag = findTag(item);
        if (tag && !tag.verified) {
          updateTag({ ...tag, verified: true });
        }
      });
    } catch (error) {
      handleServerError(error);
    }
  };

  const findTag = (id: GridRowId): Tag | undefined => {
    return tags.find((tag: Tag) => tag.uuid === id);
  };

  const handleSearch = debounce((search: any) => {
    const searchValue = search.target.value;
    setSearchText(searchValue);
    filterRows(filters, searchValue);
  }, 500);

  const getSearchedTags = (searchValue: string): Tag[] => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    return tags.filter((tag: any) =>
      Object.keys(tag).some((field: any) => searchRegex.test(tag[field]?.toString()))
    );
  };

  const filterRows = (filters: any, searchValue: string) => {
    prepareFilters(filters);
    const searchedTags = getSearchedTags(searchValue);
    const filteredRows = filter(searchedTags, filters);
    setRows(filteredRows);
  };

  const handleFiltersChange = (filterItems: any) => {
    setFilters(filterItems);
    filterRows(filterItems, searchText);
  };

  const prepareFilters = (filters: any) => {
    Object.keys(filters).forEach(key => {
      const fieldValue = filters[key];
      if (fieldValue && typeof fieldValue === 'object') {
        prepareFilters(fieldValue);
      }
      if (fieldValue === '') {
        delete filters[key];
      }
      if (fieldValue === 'true') {
        filters[key] = true;
      }
      if (fieldValue === 'false') {
        filters[key] = false;
      }
    });
  };

  const handleTagCreate = (newTag: Tag) => {
    setTags(tags => [...tags, newTag]);
    setRows(tags => [...tags, newTag]);
  };

  useEffect(() => {
    getTags();
    fetchTagsGroup();
  }, []);

  return {
    rows,
    loading,
    searchText,
    selectionModel,
    mergeIsAvailable,
    isCreateDialogOpen,
    handleSearch,
    groups,
    verifyIsAvailable,
    isMergeDialogOpen,
    filteredTags,
    handleClearSelectedTags,
    handleCreateNewTag,
    handleMergeTags,
    deleteSelectedTags,
    verifySelectedTags,
    handleFiltersChange,
    handleCellEditCommit,
    setSelectionModel,
    setCreateDialogOpen,
    setCreateDialogClose,
    setMergeDialogOpen,
    setMergeDialogClose,
  };
};
