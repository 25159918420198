import { Dispatch } from 'redux';

import { LoginData, UpdateUser, User, UserDashboards } from '@vyce/core/src/types';
import { updateMeRequest, userMeRequest } from '@vyce/core/src/api/users';
import { loginRequest } from '@vyce/core/src/api/auth';
import { getUserDashboardsRequest, updateUserDashboardsRequest } from '@vyce/core/src/api/dashboards';
import { getFromLS, saveToLS } from '@vyce/core/src/utils/local-storage';

import { store } from '../store';
import { ActionType } from '../action-types';
import { UserAction } from '../actions/user';
import { HelperAction } from '../actions/helper';

const updateToken = (token: string, dispatch: Dispatch<HelperAction>) => {
  saveToLS('token', token);
  dispatch({
    type: ActionType.SET_ACCESS_TOKEN,
    payload: token,
  });
};

const handleMeRequest = async (token: string): Promise<User> => {
  const meResult = await userMeRequest(token);
  const userData: User = meResult.data || {};
  return { ...userData };
};

const handleUserDashboardsRequest = async (token: string): Promise<UserDashboards> => {
  const res = await getUserDashboardsRequest(token as string);
  return res.data;
};

export const setUserData = (data: User) => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: ActionType.SET_USER_DATA,
      payload: data,
    });
  };
};

export const clearUser = () => {
  return (dispatch: Dispatch<UserAction | HelperAction>) => {
    saveToLS('token', '');
    dispatch({
      type: ActionType.SET_INITIAL_USER_STATE,
    });
  };
};

export const updateUserDashboards = (dashboards: UserDashboards) => {
  return async (dispatch: Dispatch<UserAction>) => {
    let token = store.getState().helper.access_token;
    try {
      await updateUserDashboardsRequest(token, dashboards);
      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { dashboards },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const me = () => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    //TODO remove token from redux
    const helper = store.getState().helper;
    let token = helper.access_token;
    if (!token) {
      token = getFromLS('token');
      dispatch({
        type: ActionType.SET_ACCESS_TOKEN,
        payload: token,
      });
    }
    try {
      const userState = await handleMeRequest(token as string);
      const dashboards = await handleUserDashboardsRequest(token as string);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { ...userState, dashboards },
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      clearUser();
    }
  };
};

export const userLogin = ({
  username,
  password,
  recaptcha_token,
  handleServerError,
  showNotification,
  redirectUrl,
}: LoginData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: true,
      });
      const result = await loginRequest({ username, password, client_secret: recaptcha_token });
      const token = result.data.access_token;
      updateToken(token, dispatch);

      const userState = await handleMeRequest(token as string);
      if (!userState.is_superuser) {
        dispatch({
          type: ActionType.SET_LOADING,
          payload: false,
        });
        showNotification({
          message: 'You have no access to Vyce Legend.',
          description: 'Ask DEV team to give you access.',
          options: {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            action: {
              type: null,
              buttonText: '',
            },
            permanent: true,
          },
        });
      }
      const dashboards = await handleUserDashboardsRequest(token as string);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { ...userState, dashboards },
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      if (redirectUrl) {
        dispatch({
          type: ActionType.SET_REDIRECT_TO,
          payload: redirectUrl,
        });
      }
    } catch (error) {
      handleServerError(error);
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
    }
  };
};

export const updateUser = ({
  data,
  isNotify,
  callback,
  showNotification,
  handleServerError,
}: UpdateUser) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    const token = store.getState().helper.access_token;
    const uuid = store.getState().user.uuid;
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: true,
      });
      const result = await updateMeRequest(token as string, uuid, data);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: result.data,
      });
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      if (isNotify) {
        showNotification({
          message: 'Your data successfully updated',
          options: {
            variant: 'success',
          },
        });
      }
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};
