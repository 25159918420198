import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { TbPencil } from 'react-icons/tb';
import { Theme } from '@material-ui/core/styles';

import { getSurveysRequest } from '@vyce/core/src/api/survey';
import { deleteSurveyRequest } from '@vyce/core/src/api/legend/surveys';
import { ShortSurvey } from '@vyce/core/src/types';
import { AppIconButton } from '@vyce/core/src/components/AppIconButton';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { CreateSurveyDialog } from './components/CreateSurveyDialog';
import { DeleteWarningDialog } from './components/DeleteWarningDialog';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useStyles from '../styles';

export const Surveys: React.FC = () => {
  const { handleServerError } = useContext(NotificationContext);
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const [openCreateSurveyDialog, setOpenCreateSurveyDialog] = useState<boolean>(false);
  const [openDeleteSurveyDialog, setOpenDeleteSurveyDialog] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<ShortSurvey[]>([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState<string | undefined>();
  const { access_token } = useTypedSelector(state => state.helper);

  const getSurveys = async () => {
    try {
      const res = await getSurveysRequest();
      setSurveys(res.data.items);
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteSurvey = async () => {
    if (!selectedSurveyId) {
      return;
    }
    try {
      await deleteSurveyRequest(access_token, selectedSurveyId);
      handleDialogClose();
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  const handleDialogClose = () => {
    setOpenCreateSurveyDialog(false);
    setOpenDeleteSurveyDialog(false);
    setTimeout(() => setSelectedSurveyId(undefined), 1000);
    getSurveys();
  };

  const handleDeleteIconClick = (id: string) => {
    setSelectedSurveyId(id);
    setOpenDeleteSurveyDialog(true);
  };

  const handleEditIconClick = (id: string) => {
    setSelectedSurveyId(id);
    setOpenCreateSurveyDialog(true);
  };

  return (
    <Box className={classes.moduleContainer}>
      <Button
        onClick={() => setOpenCreateSurveyDialog(true)}
        startIcon={<AiOutlinePlus />}
        color="primary"
        variant="contained">
        Create Survey
      </Button>

      <Box marginTop={2}>
        <Grid container spacing={2}>
          {surveys?.map((survey, index) => (
            <Grid item key={'survey' + index} xs={12} md={6}>
              <Paper variant="outlined" className={classes.surveyPaper}>
                <Typography variant="h6">{survey.name}</Typography>

                <Box display="flex">
                  <AppIconButton onClick={() => handleEditIconClick(survey.uuid)} variant="secondary">
                    <TbPencil color={theme.palette.secondary.dark} size="18px" />
                  </AppIconButton>

                  <Box marginLeft={1}>
                    <AppIconButton onClick={() => handleDeleteIconClick(survey.uuid)} variant="error">
                      <HiOutlineTrash color={theme.palette.error.dark} size="18px" />
                    </AppIconButton>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <CreateSurveyDialog
        selectedSurveyId={selectedSurveyId}
        open={openCreateSurveyDialog}
        handleDialogClose={handleDialogClose}
      />
      <DeleteWarningDialog
        open={openDeleteSurveyDialog}
        handleDialogClose={handleDialogClose}
        deleteSurvey={deleteSurvey}
      />
    </Box>
  );
};
