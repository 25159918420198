import { User, RoleItem, RolesDTO } from '../../../types';
import { roleMapper, defaultRoleOptions } from '../config';
import { RoleOptions, TransformedGeneralRoleData, TransformedPreviewRoleData } from '../types';

const defaultPreviewData = { uuid: '', company_name: '', ...defaultRoleOptions };

export const mapRoles = (roles?: RoleItem[]) => {
  if (!roles) {
    return defaultRoleOptions as RoleOptions;
  }
  return roles.reduce((sum, current) => {
    const key = roleMapper[current.module as keyof typeof roleMapper] as keyof RoleOptions;
    sum[key] = current.role ?? '';
    return sum;
  }, {} as RoleOptions);
};

export const preparePreviewData = (data?: RolesDTO): TransformedPreviewRoleData[] => {
  if (!data) return [];

  return data.roles_by_companies.map((companyRoles, index) => {
    return {
      uuid: `${data.user_id}_${index}`,
      company_name: companyRoles.company_name,
      ...mapRoles(companyRoles.roles),
    };
  });
};

export const prepareGeneralData = (data: User[]): TransformedGeneralRoleData[] => {
  if (!data) return [];
  const preparedData = data.map(({ first_name, last_name, roles, uuid, gender = null, ni_number = '' }) => {
    if (roles?.roles_by_companies?.length === 0) {
      return [{ ...defaultPreviewData, first_name, last_name, gender, ni_number, uuid }];
    }
    return preparePreviewData(roles).map(
      (data, index) =>
        ({
          first_name: index === 0 ? first_name : '',
          last_name: index === 0 ? last_name : '',
          gender,
          ni_number: index === 0 ? ni_number : '',
          ...data,
        } as TransformedGeneralRoleData)
    );
  });

  return preparedData.reduce((sum, current) => {
    sum = [...sum, ...current];
    return sum;
  }, []);
};
