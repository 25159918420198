import { useEffect, useState, useCallback, useContext } from 'react';

import { fetchUserRolesRequest } from '@vyce/core/src/api/legend/users';

import { ModuleProps, TransformedPreviewRoleData } from '../types';
import { preparePreviewData } from '../utils';
import { NotificationContext } from '../../../contexts/notificationContext';

export const usePreviewGridData = ({ ...restProps }: ModuleProps) => {
  const [loading, setLoading] = useState(false);
  const { handleServerError } = useContext(NotificationContext);
  const [roles, setRoles] = useState<TransformedPreviewRoleData[]>([]);
  const userId = restProps.mode === 'preview' && restProps.userId ? restProps.userId : '';

  const fetchRoles = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchUserRolesRequest({
        userId,
      });
      setRoles(preparePreviewData(res.data));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [userId]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return {
    loading,
    roles,
    total: roles?.length ?? 0,
  };
};
