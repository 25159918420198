import React from 'react';

import { Box, Button } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { AdaptiveSearch, FilterSystem, AppDataGridWithSavedPage } from '@vyce/core/src/components';

import { LegendInviteButton } from '../../components';
import { filtersSections, columns } from './config';
import { useModuleData } from './hooks';

export const UsersModule = () => {
  const {
    substring,
    users,
    total,
    loading,
    sortModel,
    handleFilterChange,
    handlePageChange,
    handleSortModelChange,
    handleSearchChange,
    handleAddMemberClick,
    setOffset,
  } = useModuleData();

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <AdaptiveSearch
          variant="paper"
          searchText={substring || ''}
          handleSearchChange={handleSearchChange}
        />

        <Box display="flex" gridGap={16}>
          <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />

          <LegendInviteButton />
          {/* TODO uncomment when backend will be ready */}
          {/*  <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AiOutlinePlus />}
            onClick={handleAddMemberClick}>
            Add New Member
          </Button> */}
        </Box>
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={users}
        getRowId={row => row.uuid}
        columns={columns}
        height="calc(100vh - 240px)"
        rowCount={total}
        loading={loading}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="users"
        setOffset={setOffset}
      />
    </>
  );
};
