import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { CompanyGeneralInfo } from '@vyce/core/src/views/profile/company/CompanyGeneralInfo';
import { saveLogoRequest, updateCompanyRequest } from '@vyce/core/src/api/legend/companies';
import { BankAccount, Company, CompanyPhoto, Testimonial } from '@vyce/core/src/types';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { CompanyBankDetails } from '@vyce/core/src/views/profile/company/CompanyBankDetails';
import { CompanySocials } from '@vyce/core/src/views/profile/company/CompanySocials';
import { CompanyTestimonials } from '@vyce/core/src/views/profile/company/CompanyTestimonials';
import { CompanyCaseStudies } from '@vyce/core/src/views/profile/company/CompanyCaseStudies';
import { CompanyPhotos } from '@vyce/core/src/views/profile/company/CompanyPhotos';
import { PageLoading } from '@vyce/core/src/components';
import {
  createOrUpdateCompanyBankAccountRequest,
  getCompanyBankAccountRequest,
} from '@vyce/core/src/api/legend/pay';
import { getCompanyExtendedDataRequest } from '@vyce/core/src/api/companies';

import { useTypedSelector } from '../../hooks';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

export const RouteLegendCompanyProfileComponent: React.FC = () => {
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const { handleServerError } = useContext(NotificationContext);
  const { access_token } = useTypedSelector(state => state.helper);
  const [company, setCompany] = useState<Company | undefined>();
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [photos, setPhotos] = useState<CompanyPhoto[]>([]);
  const mountedRef = useRef(true);
  const [bankDetails, setBankDetails] = useState<BankAccount>();
  const [loading, setLoading] = useState<boolean>(true);

  const getCompany = useCallback(async () => {
    const urlItems = getUrlItems(id);
    const companyId = urlItems?.id;
    if (!mountedRef.current || !companyId) {
      return setLoading(false);
    }

    try {
      const companyRes = await getCompanyExtendedDataRequest(access_token, companyId);
      const bankDetailsRes = await getCompanyBankAccountRequest({
        companyId,
      });

      setBankDetails(bankDetailsRes.data);
      setCompany(companyRes.data?.company);
      setPhotos(companyRes.data?.photos || []);
      setTestimonials(companyRes.data?.testimonials || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [access_token, id]);

  useEffect(() => {
    getCompany();
    return () => {
      mountedRef.current = false;
    };
  }, [getCompany]);

  if (loading)
    return (
      <Box height="70vh" overflow="hidden">
        <PageLoading />
      </Box>
    );

  switch (tab) {
    case 'general':
      return (
        <CompanyGeneralInfo
          company={company}
          setCompany={setCompany}
          token={access_token}
          saveLogoRequest={saveLogoRequest}
          updateCompanyRequest={updateCompanyRequest}
        />
      );
    case 'bank':
      return (
        <CompanyBankDetails
          bankDetails={bankDetails}
          setBankDetails={setBankDetails}
          companyId={company?.uuid}
          createOrUpdateCompanyBankAccountRequest={createOrUpdateCompanyBankAccountRequest}
        />
      );
    case 'socials':
      return (
        <CompanySocials
          setCompany={setCompany}
          company={company}
          token={access_token}
          updateCompanyRequest={updateCompanyRequest}
        />
      );
    case 'testimonials':
      return (
        <CompanyTestimonials
          testimonials={testimonials}
          company={company}
          token={access_token}
          getCompanyData={getCompany}
        />
      );
    case 'studies':
      return <CompanyCaseStudies />;
    case 'photos':
      return (
        <CompanyPhotos photos={photos} getCompanyData={getCompany} company={company} token={access_token} />
      );
    default:
      return <></>;
  }
};
