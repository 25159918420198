import { useMemo } from 'react';

import { useActions, useTypedSelector } from '../../../hooks';
import { WidgetModule } from '../../widgetModule';
import { LegendInviteButton } from '../../../components';

export const useTimeDashboardData = () => {
  const { dashboards } = useTypedSelector(state => state.user);
  const { selectedCompany } = useTypedSelector(state => state.helper);

  const { updateUserDashboards } = useActions();

  const contextOptions = useMemo(
    () => ({
      dashboards,
      name: 'Legend',
      selectedCompanyId: selectedCompany?.uuid ?? '',
      permissions: [],
      inviteButtonComponent: LegendInviteButton,
      updateUserDashboards,
      widgetComponent: WidgetModule,
    }),
    [dashboards, selectedCompany?.uuid, updateUserDashboards]
  );
  return {
    contextOptions,
  };
};
