import { useEffect, useState } from 'react';

import { BillingData, Subscription } from '@vyce/core/src/types';
import { getBillingDataRequest, getSubscriptionDataRequest } from '@vyce/core/src/api/billing';

import { useTypedSelector } from '../../../hooks';

export const useSubscriptionData = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<Subscription>();
  const { companies, access_token, selectedCompany } = useTypedSelector(state => state.helper);

  const [billingData, setBillingData] = useState<BillingData>();

  const getBillingData = async () => {
    if (!selectedCompany?.uuid) {
      return;
    }
    try {
      const res = await getBillingDataRequest({ token: access_token, companyId: selectedCompany.uuid });
      setBillingData(res.data);
    } catch (e) {
      console.error(e);
      setBillingData(undefined);
    }
  };

  const getSubscription = async () => {
    if (!selectedCompany?.uuid) {
      return;
    }
    try {
      setLoading(true);
      const res = await getSubscriptionDataRequest({ token: access_token, companyId: selectedCompany.uuid });
      setSubscription(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setSubscription(undefined);
    }
  };

  useEffect(() => {
    if (selectedCompany?.uuid) {
      getBillingData();
      getSubscription();
    } else {
      setSubscription(undefined);
    }
  }, [selectedCompany]);

  return {
    loading,
    selectedCompany,
    companies,
    subscription,
    billingData,
  };
};
