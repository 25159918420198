import { BiBuildings } from 'react-icons/bi';
import { FiBriefcase, FiUsers } from 'react-icons/fi';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { MdOutlinePayments } from 'react-icons/md';
import { AiOutlineTags } from 'react-icons/ai';
import { FiCreditCard } from 'react-icons/fi';
import { RiSurveyLine, RiTeamLine } from 'react-icons/ri';
import { TbSend } from 'react-icons/tb';
import { LuBrainCircuit } from 'react-icons/lu';
import { WiTime4 } from 'react-icons/wi';

import { NavItem, TabItem } from '@vyce/core/src/types';

export const LEGEND_NAV_ITEMS: NavItem[] = [
  { name: 'Dashboard', icon: HiOutlineViewGrid, link: '/dashboard' },
  {
    name: 'User Management',
    icon: RiTeamLine,
    link: '/user-management/users',
  },
  { name: 'Hiring', icon: FiBriefcase, link: '/hiring' },
  { name: 'Teams', icon: FiUsers, link: '/teams' },
  { name: 'Payroll', icon: MdOutlinePayments, link: '/payroll/timesheets' },
  {
    name: 'Time & Attendance',
    icon: WiTime4,
    link: '/time/dashboard',
  },
  { name: 'Subscription & Billing', icon: FiCreditCard, link: '/subscriptions' },
  { name: 'Vyce AI', icon: LuBrainCircuit, link: '/vyce-ai' },
  { name: 'Companies', icon: BiBuildings, link: '/companies', isBottom: true },
  { name: 'Emails & SMS', icon: TbSend, link: '/emails-and-sms/email', isBottom: true },
  { name: 'Surveys', icon: RiSurveyLine, link: '/surveys', isBottom: true },
  { name: 'Tags', icon: AiOutlineTags, link: '/tags', isBottom: true },
];

export const EMAILS_AND_SMS_TABS: TabItem[] = [
  {
    label: 'Email',
    link: '/emails-and-sms/email',
  },
  {
    label: 'SMS',
    link: '/emails-and-sms/sms',
  },
];
