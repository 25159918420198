import { useEffect, useState, useCallback, useContext } from 'react';

import { fetchUsersRequest } from '@vyce/core/src/api/legend/users';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { User } from '@vyce/core/src/types';

import { ModuleProps, TransformedGeneralRoleData } from '../types';
import { prepareGeneralData } from '../utils';
import { useCommonModuleData } from './useCommonModuleData';
import { NotificationContext } from '../../../contexts/notificationContext';

export const useGeneralGridData = ({ handleInviteButtonClick }: ModuleProps) => {
  const [loading, setLoading] = useState(false);
  const { handleServerError } = useContext(NotificationContext);
  const [roles, setRoles] = useState<TransformedGeneralRoleData[]>([]);
  const {
    total,
    offset,
    substring,
    sortModel,
    setTotal,
    handlePageChange,
    handleSortModelChange,
    handleSearchChange,
  } = useCommonModuleData();

  const fetchRoles = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchUsersRequest({
        offset,
        limit: GRID_PAGE_SIZE,
        substring,
        order_by: formatSortModel<User>(sortModel),
        roles: true,
      });
      setRoles(prepareGeneralData(res.data.items));
      setTotal(res.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [offset, sortModel, substring]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return {
    loading,
    roles,
    pageSize: roles.length,
    total,
    sortModel,
    handlePageChange,
    handleSortModelChange,
    handleInviteButtonClick,
    handleSearchChange,
  };
};
