import React, { useContext } from 'react';

import { getEmployeeListRequest, removeEmployeeFromPayScheduleRequest } from '@vyce/core/src/api/legend/pay';
import { EmployeeList } from '@vyce/core/src/views/payroll/components/EmployeeList';
import { PayModuleContext } from '@vyce/core/src/contexts/payrollModule';

import { useTypedSelector } from '../../../hooks';
import { LegendInviteButton } from '../../../components';

export const LegendEmployeeList: React.FC = () => {
  const { selectedCompanyId } = useContext(PayModuleContext);
  const { access_token } = useTypedSelector(state => state.helper);

  return (
    <EmployeeList
      token={access_token}
      removeEmployeeFromPayScheduleRequest={removeEmployeeFromPayScheduleRequest}
      companyId={selectedCompanyId}
      getEmployeeListRequest={getEmployeeListRequest}>
      <LegendInviteButton />
    </EmployeeList>
  );
};
