import React, { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { CssBaseline, LinearProgress, ThemeProvider, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { getFromLS } from '@vyce/core/src/utils/local-storage';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { Header } from '@vyce/core/src/components/Header';
import { Sidebar } from '@vyce/core/src/components/Sidebar';
import { ColorThemeProvider } from '@vyce/core/src/contexts/colorThemeContext';
import { MainNotifications } from '@vyce/core/src/components/MainNotifications';
import { ThemeType, ColorThemeType } from '@vyce/core/src/types';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import createRoutes from '../../router';
import { useActions, useTypedSelector } from '../../hooks';
import useStyles from './styles';

const routes = createRoutes();

interface Props {
  changeColorTheme: (type: ThemeType) => void;
  themeType?: ThemeType;
  colorTheme: ColorThemeType;
  theme: Theme;
}

export const Main: React.FC<Props> = ({ changeColorTheme, colorTheme, themeType, theme }) => {
  const classes = useStyles();
  const { clearRedirectTo, me, clearUser, clearHelpers, fetchCompanies } = useActions();
  const [loading, setLoading] = useState<boolean>(true);
  const token = getFromLS('token');
  const history = useHistory();
  const { user, helper } = useTypedSelector(state => state);
  const { redirectTo, navItems, notification } = helper;
  const { isDesktop } = useContext(DeviceContext);
  const [open, setOpen] = useState<boolean>(true);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const isAuth = !!user?.first_name;

  const handleDrawerToggle = () => {
    if (isDesktop) {
      return setOpen(!open);
    }
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    async function fetchUserData() {
      await me();
      setLoading(false);
    }

    if (token) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetchCompanies();
    }
  }, [token]);

  // listens and redirects from state
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      clearRedirectTo();
    }
  }, [clearRedirectTo, redirectTo, history]);

  const logout = () => {
    setOpen(false);
    setMobileOpen(false);
    clearHelpers();
    clearUser();
    // make sure localStorage.clear function will be last
    setTimeout(() => localStorage.clear());
  };

  return (
    <ColorThemeProvider value={{ colorTheme }}>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          {isAuth && (
            <Header
              themeType={themeType}
              loading={loading}
              handleDrawerToggle={handleDrawerToggle}
              changeColorTheme={changeColorTheme}
              open={open}
              user={user}>
              <Typography variant="subtitle2" color="primary">
                {user.first_name} {user.last_name}
              </Typography>
            </Header>
          )}

          {!loading && user.uuid && (
            <>
              <Sidebar
                handleDrawerToggle={handleDrawerToggle}
                open={open}
                logout={logout}
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
                navItems={navItems}
              />
            </>
          )}
          <main
            id={MAIN_CONTAINER_ID}
            className={clsx(classes.content, {
              [classes.contentShift]: open,
              [classes.authLayout]: !isAuth,
            })}>
            {loading ? <LinearProgress className={classes.progress} /> : <>{routes}</>}
          </main>

          <MainNotifications userId={user.uuid} />
        </div>
      </ThemeProvider>
    </ColorThemeProvider>
  );
};
