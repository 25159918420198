import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useTypedSelector } from '../hooks/useTypedSelector';

export const LegendRoute = ({ component, ...rest }: any) => {
  const { user } = useTypedSelector((state) => state);

  const allowed = user.uuid && (user.is_superuser || user.is_staff);
  const pathname = user.uuid ? '/dashboard' : '/auth/login';

  const routeComponent = (props: any) =>
    allowed ? React.createElement(component, props) : <Redirect to={{ pathname }} />;
  return <Route {...rest} render={routeComponent} />;
};
