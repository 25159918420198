import React, { useContext } from 'react';

import { Box } from '@material-ui/core';

import { useLocationUsersData } from '@vyce/core/src/modules/timeModule/hooks';
import { LocationUsers } from '@vyce/core/src/views/time/components';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { DeviceContext } from '@vyce/core/src/contexts';

import { useTypedSelector } from '../../hooks';
import { LegendInviteButton } from '../../components';

export const LocationsUsers: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { isMobile } = useContext(DeviceContext);

  const {
    workers,
    total,
    sortModel,
    loadingTableData,
    closeGridAction,
    handlePageChange,
    handleSearchChange,
    handleSortModelChange,
    setOffset,
  } = useLocationUsersData({ siteId: undefined, selectedCompany });

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={3} gridGap={8}>
        <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />

        <LegendInviteButton />
      </Box>

      <LocationUsers
        loading={loadingTableData}
        total={total}
        sortModel={sortModel}
        tableHeight="calc(100vh - 270px)"
        handleSortModelChange={handleSortModelChange}
        handlePageChange={handlePageChange}
        closeGridAction={closeGridAction}
        workers={workers}
        setOffset={setOffset}
      />
    </>
  );
};
