import {
  editPermissionRequest,
  getRolesRequest,
  getStaffRequest,
  sendInviteRequest,
} from '@vyce/core/src/api/legend/management';

import { useTypedSelector } from '../../../hooks';

export const useModuleData = () => {
  const { selectedCompany, companies } = useTypedSelector(state => state.helper);

  return {
    selectedCompany,
    companies,
    editPermissionRequest,
    getRolesRequest,
    getStaffRequest,
    sendInviteRequest,
  };
};
