import { GridColDef } from '@mui/x-data-grid';

export const roleMapper = {
  Company: 'company',
  Payroll: 'payroll',
  Connect: 'connect',
  Teams: 'teams',
  'Time&Attendance': 'time',
};

export const modes = {
  general: 'general',
  preview: 'preview',
};

export const defaultRoleOptions = { company: '', payroll: '', connect: '', teams: '', time: '' };

export const commonColumns: GridColDef[] = [
  {
    field: 'company_name',
    headerName: 'Company Name',
    flex: 0.1,
    disableColumnMenu: true,
    minWidth: 180,
    sortable: false,
  },
  {
    field: 'company',
    headerName: 'Company',
    flex: 0.1,
    disableColumnMenu: true,
    minWidth: 120,
    sortable: false,
  },
  {
    field: 'payroll',
    headerName: 'Payroll',
    flex: 0.1,
    disableColumnMenu: true,
    minWidth: 120,
    sortable: false,
  },
  {
    field: 'connect',
    headerName: 'Connect',
    flex: 0.1,
    disableColumnMenu: true,
    minWidth: 120,
    sortable: false,
  },
  {
    field: 'teams',
    headerName: 'Teams',
    flex: 0.1,
    disableColumnMenu: true,
    minWidth: 120,
    sortable: false,
  },
  {
    field: 'time',
    headerName: 'Time',
    flex: 0.1,
    disableColumnMenu: true,
    minWidth: 120,
    sortable: false,
  },
];
