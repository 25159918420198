import React from 'react';

import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { ErrorBoundary } from 'react-error-boundary';

import '@vyce/core/src/index.css';
import config, { ENVIRONMENTS } from '@vyce/core/src/api/config';
import { Fallback } from '@vyce/core/src/components';

import App from './App';
import reportWebVitals from './reportWebVitals';

if (config.environment === ENVIRONMENTS.PROD) {
  ReactGA.initialize(config.gaMeasurementIdProdLegendApp);
}

ReactDOM.render(
  <ErrorBoundary FallbackComponent={Fallback}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (config.environment === ENVIRONMENTS.PROD) {
  const SendAnalytics = () => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    });
  };
  reportWebVitals(SendAnalytics);
}
