import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { Route, Switch, useHistory } from 'react-router-dom';

import { DeviceContext, UserManagementProvider } from '@vyce/core/src/contexts';
import { CommonNavBarBlock } from '@vyce/core/src/components';
import { getPaddingForContent } from '@vyce/core/src/utils';

import {
  UsersModule,
  StaffManagementModule,
  InviteListModule,
  RolesAndPermissionModule,
} from '../../modules';
import { useTypedSelector } from '../../hooks';
import { LegendCompanyFilter } from '../main/components/LegendCompanyFilter';
import { USER_MANAGEMENT_TABS } from './constants';

export const LegendUserManagement: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const history = useHistory();
  const padding = getPaddingForContent(isMobile);

  return (
    <>
      <CommonNavBarBlock tabItems={USER_MANAGEMENT_TABS}>
        {history.location.pathname === '/user-management/admins' && <LegendCompanyFilter />}
      </CommonNavBarBlock>

      <Box padding={padding}>
        <UserManagementProvider value={{ selectedCompanyId: selectedCompany?.uuid || null }}>
          <Switch>
            <Route path={'/user-management/users'} component={UsersModule} />
            <Route path={'/user-management/admins'} component={StaffManagementModule} />
            <Route path={'/user-management/invites'} component={InviteListModule} />
            <Route path={'/user-management/roles'} component={RolesAndPermissionModule} />
          </Switch>
        </UserManagementProvider>
      </Box>
    </>
  );
};
