import React from 'react';

import { DailyActivity } from '@vyce/core/src/views/time/ActivityLogs/DailyActivity';

import { useTypedSelector } from '../../hooks';

export const LegendDailyActivity: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return <DailyActivity canViewLocation={true} companyId={selectedCompany?.uuid} />;
};
