import React from 'react';

import { useTypedSelector } from '../../hooks';
import { EditBillingContact } from '@vyce/core/src/views/subscription/components/EditBillingContact';

export const LegendEditBillingContact: React.FC = () => {
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);

  return <EditBillingContact selectedCompany={selectedCompany} token={access_token} />;
};
