import React from 'react';

import { TimeLogsByLocation } from '@vyce/core/src/views/time/TimeLogsByLocation';

import { useTypedSelector } from '../../hooks';

export const LegendTimeLogsByLocation: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return <TimeLogsByLocation companyId={selectedCompany?.uuid} />;
};
