import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts';
import { BackToData, Site, TimeAndAttendanceLocation } from '@vyce/core/src/types';
import { CommonNavBarBlock, PageLoading } from '@vyce/core/src/components';
import { getSitesRequest } from '@vyce/core/src/api/time';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { TimeModuleProvider } from '@vyce/core/src/contexts';
import { TIME_AND_ATTENDANCE_TABS } from '@vyce/core/src/modules/timeModule/constants';
import { siteToLocation } from '@vyce/core/src/modules/timeModule/utils';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { ManualClockModule, TimeDashboardModule } from '../../modules';
import { LocationPage } from './LegendLocationPage';
import { LocationsUsers } from './LegendLocationWorkers';
import { useTypedSelector } from '../../hooks';
import { LegendCompanyFilter } from '../main/components/LegendCompanyFilter';
import { LegendDailyActivity } from './LegendDailyActivity';
import { LegendLocationsAndShifts } from './LegendLocationsAndShifts';
import { LegendLocationOnboarding } from './LegendLocationOnboarding';
import { LegendTimeLogsByPeriod } from './LegendTimeLogsByPeriod';
import { LegendTimeLogsByLocation } from './LegendTimeLogsByLocation';
import { LegendTimeLogsByWorker } from './LegendTimeLogsByWorker';
import { LegendWorkerDetails } from './LegendWorkerDetails';
import { LegendTimeLogsByShift } from './LegendTimeLogsByShift';
import { LegendTimeSettings } from './LegendTimeSettings';

export const LegendTimeAndAttendance: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);
  const [locations, setLocations] = useState<TimeAndAttendanceLocation[]>([]);
  const [backTo, setBackTo] = useState<BackToData | null>(null);
  const padding = getPaddingForContent(isMobile);

  const selectedCompanyId = selectedCompany?.uuid ?? '';

  const getLocations = useCallback(async () => {
    if (!selectedCompanyId) {
      return setLocations([]);
    }

    setLoading(true);

    try {
      const res = await getSitesRequest(selectedCompanyId, { limit: 500, order_by: [] });
      const locations = res.data.items?.map((item: Site) => siteToLocation(item));
      setLocations(locations);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId]);

  const contextOptions = useMemo(
    () => ({ backTo, setBackTo, getLocations, locations }),
    [backTo, getLocations, locations]
  );

  useEffect(() => {
    getLocations();
  }, [selectedCompany?.uuid]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <TimeModuleProvider value={contextOptions}>
      <Switch>
        <Route path={'/time/create-location'} component={LegendLocationOnboarding} />

        <Box>
          <CommonNavBarBlock tabItems={TIME_AND_ATTENDANCE_TABS} backTo={backTo}>
            <LegendCompanyFilter />
          </CommonNavBarBlock>

          <Box padding={padding} height="100%">
            <Route exact path={'/time/dashboard'} component={TimeDashboardModule} />
            <Route exact path={'/time/locations-and-shifts/:id'} component={LocationPage} />
            <Route exact path={'/time/locations-and-shifts'} component={LegendLocationsAndShifts} />
            <Route exact path={'/time/timelogs'} component={LegendTimeLogsByPeriod} />
            <Route exact path={'/time/timelogs/:period'} component={LegendTimeLogsByLocation} />
            <Route exact path={'/time/timelogs/:period/:siteId'} component={LegendTimeLogsByWorker} />
            <Route exact path={'/time/timelogs/:period/:siteId/:userId'} component={LegendTimeLogsByShift} />
            <Route
              exact
              path={'/time/timelogs/:period/:siteId/:userId/:date'}
              component={LegendDailyActivity}
            />
            <Route exact path={'/time/users/:id/:tag'} component={LegendWorkerDetails} />
            <Route path={'/time/users/:userId/:tag/:date'} component={LegendDailyActivity} />
            <Route exact path={'/time/users'} component={LocationsUsers} />
            <Route path={'/time/add-new-location'} component={LegendLocationOnboarding} />
            <Route path={'/time/manual'} component={ManualClockModule} />
            <Route path={'/time/settings'} component={LegendTimeSettings} />
          </Box>
        </Box>
      </Switch>
    </TimeModuleProvider>
  );
};
