import { useContext, useEffect, useMemo, useState } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';

import { useBooleanState } from '@vyce/core/src/hooks';
import { getCheckInWorkersRequest } from '@vyce/core/src/api/time';
import { formatTimeSortModel } from '@vyce/core/src/utils/sorting';
import { CheckInWorkerDTO } from '@vyce/core/src/api/types';
import { useInterval } from '@vyce/core/src/hooks/useInterval';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { TIME_INTERFACE_PERMISSIONS } from '@vyce/core/src/types';
import { useTabFocus } from '@vyce/core/src/hooks/useTabFocus';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { Props } from '../types';

const defaultOption = { value: 'all', name: 'All Locations' };
const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'desc' }];

export const useWidgetData = ({ selectedCompanyId, locations, userPermissions }: Props) => {
  const [workers, setWorkers] = useState<CheckInWorkerDTO[]>([]);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(defaultOption.value);

  const { isTabFocused } = useTabFocus();
  const { handleServerError } = useContext(NotificationContext);
  const { sortModel, offset, total, setTotal, handleSortModelChange, handlePageChange } = useTable({
    defaultSortModel,
  });
  const cnaViewWorker = useMemo(
    () => (userPermissions?.length ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.USERS) : true),
    [userPermissions]
  );

  const locationOptions = useMemo(() => {
    if (!locations) return [defaultOption];
    const options = locations.map(item => ({ value: item.uuid, name: item.name }));
    return [...options, defaultOption];
  }, [locations]);

  const getMembers = async () => {
    if (!selectedCompanyId || !isTabFocused) {
      return;
    }

    setLoadingTrue();
    try {
      const { data } = await getCheckInWorkersRequest({
        companyId: selectedCompanyId,
        payload: {
          offset: offset,
          limit: GRID_PAGE_SIZE,
          order_by: formatTimeSortModel<CheckInWorkerDTO>(sortModel),
          site_id: selectedLocationId !== 'all' ? selectedLocationId : undefined,
        },
      });
      setTotal(data.count);
      setWorkers(data.items);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  useInterval(getMembers, 10000);

  useEffect(() => {
    getMembers();
  }, [selectedCompanyId, selectedLocationId, offset, sortModel]);

  return {
    workers,
    total,
    locationOptions,
    selectedLocationId,
    loading,
    sortModel,
    handlePageChange,
    setSelectedLocationId,
    handleSortModelChange,
    cnaViewWorker,
  };
};
