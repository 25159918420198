import { createContext } from 'react';

import { GridSortModel } from '@mui/x-data-grid';

import { FilterSection, TeamMember } from '@vyce/core/src/types';

interface Context {
  loading: boolean;
  substring: string;
  teamMembers: TeamMember[];
  total: number;
  sortModel: GridSortModel;
  filtersSections?: FilterSection[];
  filters?: any;
  closeGridAction?: boolean;
  userToRemove?: TeamMember;
  handleRemoveClick?: Function;
  withProfileButton?: boolean;
  isConfirmDialogOpen?: boolean;
  exportButtonLoading: boolean;
  setOffset: (offset: number) => void;
  handleSortModelChange: (newModel: GridSortModel) => void;
  handlePageChange: (newPage: number) => void;
  handleSearchChange: (event: any) => void;
  handleFilterChange?: (filters: any) => void;
  closeConfirmDialog?: () => void;
  removeFromModule?: () => void;
  exportQualifications: () => Promise<void>;
}

export const TeamMembersModuleContext = createContext({} as Context);

export const TeamMembersModuleProvider = TeamMembersModuleContext.Provider;
