import { axios } from '@vyce/core/src/api';

import { ChangeUserPasswordArgs, GetUserArgs, GetUserData, GetUsersData, UpdateUserArgs } from '../types';
import { RolesDTO, User } from '../../types';

export const fetchUsersRequest = (data: GetUsersData & { roles?: boolean }) => {
  return axios.post<{ items: User[]; count: number }>(`/admin/users/search`, data);
};

export const fetchUserRolesRequest = ({ userId }: { userId: string }) => {
  return axios.get<RolesDTO>(`/admin/users/${userId}/roles`);
};

export const deleteUserRequest = ({ token, id }: GetUserArgs) => {
  return axios.delete(`/admin/users/${id}`);
};

export const updateUserRequest = ({ token, id, data }: UpdateUserArgs) => {
  return axios.patch(`/admin/users/${id}`, data);
};

export const changeUserPasswordRequest = ({ token, id, data }: ChangeUserPasswordArgs) => {
  return axios.put(`/admin/users${id}/change_password`, data);
};

export const addUserAvatar = (file: File, userId: string, token: string) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.put(`/admin/users/avatar/${userId}/?title=my_avatar&album=avatars`, bodyFormData);
};

export const updateUserAvatar = (file: File, userId: string, token: string) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.put(`/admin/users/avatar/${userId}/?title=my_avatar&album=avatars`, bodyFormData);
};

export const getUserAvailableTasksRequest = (token: string, userId?: string) => {
  return axios.get(`/admin/users/tasks/qualifications/${userId}`);
};

export const getUserQualificationsRequest = (token: string, userId?: string) => {
  return axios.get(`/admin/users/qualifications/${userId}`);
};

export const getUserDataRequest = ({ userId }: GetUserData) => {
  return axios.get(`/admin/users/${userId}`);
};
