import { axios } from '@vyce/core/src/api';

import { RequestWorkerData } from '@vyce/core/src/types';

interface UpdateWorkerArgs {
  token: string;
  workerId: string;
  data: RequestWorkerData;
}

export const updateWorkerRequest = ({ token, workerId, data }: UpdateWorkerArgs) => {
  return axios.patch(`/admin/connect/workers/${workerId}`, data);
};
