import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { Maintenance } from '@vyce/core/src/views/maintenance';

import { UnAuthorizedRoute } from './UnAuthorizedRoute';
import { Tags } from '../views/tags';
import { LegendRoute } from './LegendRoute';
import { Surveys } from '../views/surveys/Surveys';
import { Auth } from '../views/auth/Auth';
import { LegendDashboard } from '../views/dashboard/LegendDashboard';
import { LegendUserProfile, UserPreviewByLegend } from '../views/user-management/components';
import { PublicCompanyPreviewWrapper } from '../views/companies/components/PublicCompanyPreviewWrapper';
import { LegendCompanyProfile } from '../views/companies/LegendCompanyProfile';
import { Companies } from '../views/companies/Companies';
import { CompanyPreviewWrapper } from '../views/companies/components/CompanyPreviewWrapper';
import { LegendPayroll } from '../views/payroll/LegendPayroll';
import { EmailsAndSMS } from '../views/emails-and-sms/EmailsAndSMS';
import { LegendUserManagement } from '../views/user-management';
import { Teams } from '../views/teams';
import { LegendSubscriptionAndBilling } from '../views/subscription/LegendSubscriptionAndBilling';
import { LegendHiring } from '../views/hiring/LegendHiring';
import { LegendCandidates } from '../views/hiring/LegendCandidates';
import { LegendTimeAndAttendance } from '../views/time';
import { LegendAITool } from '../views/ai/LegendAITool';

const createRoutes = () => (
  <Switch>
    <Route exact path={'/maintenance'} component={Maintenance} />

    {/*Authentication*/}
    <UnAuthorizedRoute path="/auth" component={Auth} />

    <LegendRoute
      exact
      path={'/user-management/:tab/:userId/:tab/:companyId'}
      component={PublicCompanyPreviewWrapper}
    />
    <LegendRoute exact path={'/user-management/:tab/:id'} component={UserPreviewByLegend} />
    <LegendRoute exact path={'/user-management/:tab/:id/:tab'} component={LegendUserProfile} />
    <LegendRoute path={'/user-management'} component={LegendUserManagement} />

    <LegendRoute path={'/dashboard'} component={LegendDashboard} />

    <LegendRoute exact path={'/tags'} component={Tags} />

    <LegendRoute path={'/hiring/live/:job/candidates/:category/:candidate'} component={LegendCandidates} />
    <LegendRoute path={'/hiring/live/:job/candidates/:category'} component={LegendCandidates} />
    <LegendRoute path={'/hiring/live/:job/candidates/'} component={LegendCandidates} />
    <LegendRoute path={'/hiring/:status/:job'} component={LegendHiring} />
    <LegendRoute path={'/hiring/:status'} component={LegendHiring} />

    <LegendRoute exact path={'/teams/dashboard/:id/:tab'} component={LegendUserProfile} />
    <LegendRoute exact path={'/teams/dashboard/:id'} component={UserPreviewByLegend} />
    <LegendRoute exact path={'/teams/members/:id/:tab'} component={LegendUserProfile} />
    <LegendRoute exact path={'/teams/members/:id'} component={UserPreviewByLegend} />
    <LegendRoute exact path={'/teams/list/:team/:id/:tab'} component={LegendUserProfile} />
    <LegendRoute exact path={'/teams/list/:team/:id'} component={UserPreviewByLegend} />
    <LegendRoute path={'/teams'} component={Teams} />

    <LegendRoute path={'/time'} component={LegendTimeAndAttendance} />

    <LegendRoute path={'/subscriptions'} component={LegendSubscriptionAndBilling} />

    <LegendRoute path={'/vyce-ai'} component={LegendAITool} />

    <LegendRoute exact path={'/surveys'} component={Surveys} />

    <LegendRoute
      exact
      path={'/payroll/users/:userId/:tab/:companyId'}
      component={PublicCompanyPreviewWrapper}
    />
    <LegendRoute exact path={'/payroll/users/:id/:tab'} component={LegendUserProfile} />
    <LegendRoute exact path={'/payroll/users/:id'} component={UserPreviewByLegend} />
    <LegendRoute path={'/payroll'} component={LegendPayroll} />

    <LegendRoute exact path={'/companies/:id/:tab'} component={LegendCompanyProfile} />
    <LegendRoute exact path={'/companies/:id'} component={CompanyPreviewWrapper} />
    <LegendRoute exact path={'/companies'} component={Companies} />

    <LegendRoute path={'/emails-and-sms'} component={EmailsAndSMS} />

    <Route path="/hiring" render={() => <Redirect to={{ pathname: '/hiring/live' }} />} />
    <Route
      path="*"
      render={props => (
        <Redirect to={{ pathname: '/auth/login', state: { from: props.location.pathname } }} />
      )}
    />
  </Switch>
);

export default createRoutes;
