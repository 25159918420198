import React from 'react';

import { AppDataGrid } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';

import { usePreviewGridData } from './hooks';
import { ModuleProps } from './types';
import { commonColumns } from './config';

export const PreviewGrid = ({ inviteButton, ...restProps }: ModuleProps) => {
  const { roles, loading, total } = usePreviewGridData(restProps);

  return (
    <AppDataGrid
      rows={roles}
      noPaper
      height="calc(100vh - 250px)"
      getRowId={row => row.uuid}
      columns={commonColumns}
      loading={loading}
      pageSize={GRID_PAGE_SIZE}
      paginationMode="client"
      sortingMode="client"
      rowCount={total}
      rowsPerPageOptions={[GRID_PAGE_SIZE]}
      disableSelectionOnClick
      withProfileButton
    />
  );
};
