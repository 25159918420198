import { axios } from '@vyce/core/src/api';

import { ListStaffRequest, SendInviteRequest } from '../types';

export const getStaffRequest = (companyId: string, payload: ListStaffRequest) => {
  return axios.post(`/admin/management/staff`, { ...payload, company_id: companyId });
};

export const editPermissionRequest = (companyId: string, userId: string, data: any) => {
  return axios.patch(`/admin/management/company/${companyId}/user/${userId}`, data);
};

export const getRolesRequest = (companyId: string) => {
  return axios.get(`/admin/management/company/${companyId}/roles`);
};

export const sendInviteRequest = (companyId: string, payload: SendInviteRequest) => {
  return axios.post(`/admin/management/company/${companyId}/staff/invites`, payload);
};
