import React, { useEffect, useState, useContext, useCallback } from 'react';

import { Box } from '@material-ui/core';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid';

import { AppDataGridWithSavedPage } from '@vyce/core/src/components/AppDataGrid';
import { AdaptiveSearch, AppLink, FilterSystem, GridCellWithAvatar } from '@vyce/core/src/components';
import { CompanyForManagement, FilterSection } from '@vyce/core/src/types';
import { getCompaniesForManagementRequest } from '@vyce/core/src/api/legend/companies';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { getBooleanFilterValue, isNil } from '@vyce/core/src/utils';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useDebounceValue } from '@vyce/core/src/hooks/useDebounceValue';
import { useTable } from '@vyce/core/src/hooks/useTable';

const defaultSortModel: GridSortModel = [{ field: 'name', sort: 'asc' }];
export const Companies: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { handleServerError } = useContext(NotificationContext);
  const [filters, setFilters] = useState<any>();
  const [companies, setCompanies] = useState<CompanyForManagement[]>([]);
  const { isMobile } = useContext(DeviceContext);
  const padding = getPaddingForContent(isMobile);

  const {
    sortModel,
    offset,
    substring,
    total,
    setTotal,
    setOffset,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({ defaultSortModel });

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: params => (
        <GridCellWithAvatar
          avatarUrl={params.row.logo?.url}
          name={params.row.name}
          link={`/companies/${params.row?.name}_${params.row?.uuid}`}
        />
      ),
    },
    {
      field: 'trading_name',
      headerName: 'Trading Name',
      flex: 1.5,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'owner_id',
      headerName: 'Owner',
      renderCell: params => (
        <AppLink
          target="_blank"
          to={`/user-management/users/${params.row.first_name}_${params.row.owner_id}`}>
          {params.row.first_name} {params.row.last_name}
        </AppLink>
      ),
      flex: 1.5,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'outsourced',
      headerName: 'Outsourced Payroll',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'not_outsourced',
      headerName: 'Self Managed Payroll',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
  ];

  const filtersSections: FilterSection[] = [
    {
      title: 'Company',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Is pay Company?',
          field: 'pay_company',
          values: ['', 'Yes', 'No'],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'Status',
          field: 'status',
          values: [
            '',
            'Active',
            'Dissolved',
            'Liquidation',
            'Receiver Action',
            'Converted / Closed',
            'Voluntary Arrangement',
            'Insolvency Proceedings',
            'In Administration',
            'Open',
            'Closed',
            'Registered',
            'Removed',
          ],
          defaultValue: '',
        },
      ],
    },
  ];

  const getCompanies = useCallback(async () => {
    if (isNil(dOffset)) return;

    try {
      const res = await getCompaniesForManagementRequest({
        limit: GRID_PAGE_SIZE,
        offset: dOffset as number,
        substring,
        order_by: formatSortModel<CompanyForManagement>(sortModel),
        pay_company: getBooleanFilterValue(filters?.pay_company),
        status: filters?.status || undefined,
        type: filters?.type || undefined,
      });
      setLoading(false);
      setCompanies(res.data.items);
      setTotal(res.data.count);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [dOffset, substring, sortModel, filters]);

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  return (
    <Box padding={padding}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <AdaptiveSearch
          variant="paper"
          searchText={substring || ''}
          handleSearchChange={handleSearchChange}
        />

        <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={companies}
        getRowId={row => row.uuid}
        columns={columns}
        height="calc(100vh - 180px)"
        rowCount={total}
        loading={loading}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        setOffset={setOffset}
      />
    </Box>
  );
};
