import { Invites } from '@vyce/core/src/modules';

import { LegendInviteButton } from '../../components';
import { useModuleData } from './hooks';

export const InviteListModule = () => {
  const { selectedCompany, deleteInviteRequest, getFullInviteListRequest, resendInviteRequest } =
    useModuleData();

  return (
    <Invites
      selectedCompanyId={selectedCompany?.uuid}
      deleteInviteRequest={deleteInviteRequest}
      getInvitesRequest={getFullInviteListRequest}
      resendInviteRequest={resendInviteRequest}
      inviteButton={LegendInviteButton}
      isLegend={true}
    />
  );
};
