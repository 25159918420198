import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Button } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import type { GridColDef } from '@mui/x-data-grid';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { AppDataGrid } from '@vyce/core/src/components/AppDataGrid';
import { PaySchedule } from '@vyce/core/src/types';
import { AdaptiveSearch } from '@vyce/core/src/components/AdaptiveSearch';
import { getPaySchedulesRequest } from '@vyce/core/src/api/legend/pay';
import { PayModuleContext } from '@vyce/core/src/contexts/payrollModule';
import { AppLink } from '@vyce/core/src/components/AppLink';
import { IsVerified } from '@vyce/core/src/components/IsVerified';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useTable } from '@vyce/core/src/hooks/useTable';

export const LegendPaySchedules: React.FC = () => {
  const { handleServerError } = useContext(NotificationContext);
  const [paySchedules, setPaySchedules] = useState<PaySchedule[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedCompanyId } = useContext(PayModuleContext);
  const history = useHistory();

  const { offset, substring, total, setTotal, handlePageChange, handleSearchChange } = useTable({});

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 15,
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppLink
            to={`/payroll/schedules/${params.row.name}_${params.row.uuid}_${params.row.company?.company_id}`}>
            {params.row?.name}
          </AppLink>
        </Box>
      ),
      minWidth: 150,
    },
    {
      field: 'company',
      headerName: 'Company',
      valueGetter: params => params.row.company?.name,
      flex: 15,
      minWidth: 150,
    },
    {
      field: 'outsourced',
      headerName: 'Outsourced',
      flex: 10,
      renderCell: params => <IsVerified verified={params.row.outsourced} />,
      minWidth: 130,
    },
    {
      field: 'pay_frequency',
      headerName: 'Frequency',
      flex: 15,
      minWidth: 130,
    },
    {
      field: 'payroll_fee_base',
      headerName: 'Base Fee',
      flex: 15,
      minWidth: 130,
    },
    {
      field: 'payroll_fee_commission',
      headerName: 'Commission Fee',
      flex: 15,
      minWidth: 130,
    },
    {
      field: 'payroll_paid_by_worker',
      headerName: 'Paid By Worker',
      flex: 10,
      renderCell: params => <IsVerified verified={params.row.payroll_paid_by_worker} />,
      minWidth: 130,
    },
  ];

  const getPaySchedules = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getPaySchedulesRequest({
        substring,
        offset,
        limit: GRID_PAGE_SIZE,
        companyId: selectedCompanyId,
      });
      setTotal(res.data.count);
      setPaySchedules(res.data.items);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [offset, substring, selectedCompanyId]);

  useEffect(() => {
    getPaySchedules();
  }, [getPaySchedules]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <AdaptiveSearch
          variant="paper"
          searchText={substring || ''}
          handleSearchChange={handleSearchChange}
        />

        <Button
          startIcon={<AiOutlinePlus />}
          variant="contained"
          onClick={() => history.push('/payroll/schedules/create-pay-schedule')}
          size="small"
          color="primary">
          Create New Pay Schedule
        </Button>
      </Box>

      <AppDataGrid
        rows={paySchedules}
        noPaper
        getRowId={row => row.uuid}
        columns={columns}
        rowCount={total}
        loading={loading}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="pay schedules"
      />
    </>
  );
};
