import { useContext } from 'react';

import { TeamPage as TeamPageComponent } from '@vyce/core/src/views/teamPage';
import { TeamModuleProvider, TeamsPageContext } from '@vyce/core/src/contexts';
import { PageLoading } from '@vyce/core/src/components/PageLoading';

import { useTeamPageData } from './hooks';

export const TeamModule = () => {
  const data = useTeamPageData();
  const { loading: teamsRequestLoading } = useContext(TeamsPageContext);

  if (teamsRequestLoading) return <PageLoading />;

  return (
    <TeamModuleProvider value={data}>
      <TeamPageComponent isLegend />
    </TeamModuleProvider>
  );
};
