import { makeStyles, createStyles, Theme } from '@material-ui/core';

const drawerWidth = 230;

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      maxWidth: '100vw',
    },
    authLayout: {
      padding: '0 !important',
    },
    content: {
      flexGrow: 1,
      width: '100%',
      minHeight: '100vh',
      paddingTop: theme.spacing(8),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(7),
      },
    },
    contentShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      minHeight: 48,
      justifyContent: 'flex-end',
    },
    appbar: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `-1px 1px 0px ${theme.palette.type === 'dark' ? 'gba(255, 255, 255, 0.12)' : '#D9DBE9'}`,
      color: theme.palette.text.primary,
      [theme.breakpoints.up('sm')]: {
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    rightIcons: {
      marginRight: theme.spacing(0.5),
    },
    spacer: {
      flexGrow: 1,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
    },
    logoIconButton: {
      marginLeft: '-6px',
      padding: 0,
      marginRight: '36px',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    profileAvatarContainer: {
      marginRight: '-10px',
    },
    breadcrumbs: {
      marginBottom: theme.spacing(1),
    },
    breadcrumb: {
      fontSize: theme.typography.body2.fontSize,
    },
    breadcrumbLink: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    mobileBackButton: {
      marginLeft: theme.spacing(1),
    },
    progress: {
      margin: `-${theme.spacing(3)}px`,
      [theme.breakpoints.down('sm')]: {
        margin: `-${theme.spacing(1)}px`,
      },
    },
    servicesPaper: {
      padding: theme.spacing(3),
    },
    serviceCard: {
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
    serviceIcon: {
      marginRight: theme.spacing(2),
    },
    serviceTitle: {
      marginBottom: theme.spacing(1),
    },
    loginButton: {
      color: '#ffffff',
      borderColor: '#ffffff',
    },
    bold: {
      fontWeight: 600,
    },
  })
);
