import React from 'react';

import { Candidates } from '@vyce/core/src/views/hiring/Candidates';

import { useTypedSelector } from '../../hooks';

export const LegendCandidates: React.FC = () => {
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);
  const { first_name, last_name, phone, email } = useTypedSelector(state => state.user);

  return (
    <>
      {selectedCompany?.uuid && (
        <Candidates
          companyId={selectedCompany?.uuid}
          token={access_token}
          email={email}
          lastName={last_name}
          firstName={first_name}
          phone={phone}
        />
      )}
    </>
  );
};
