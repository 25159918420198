import { axios } from '@vyce/core/src/api';

import { CreateInvitesRequest, GetInvitesRequest } from '../types';

export const createInvitesRequest = ({ token, companyId, payload }: CreateInvitesRequest) => {
  return axios.post(`/admin/invites/companies/${companyId}`, payload);
};

// TODO Add Invites table to Legend User Management

export const getInvitesRequest = ({ companyId, limit, offset, order_by, substring }: GetInvitesRequest) => {
  return axios.post(`/admin/invites/companies/${companyId}/search`, { limit, offset, substring, order_by });
};

export const resendInviteRequest = (companyId: string, inviteId: string) => {
  return axios.post(`/admin/invites/${inviteId}/companies/${companyId}/resend`);
};

export const deleteInviteRequest = (companyId: string, inviteId: string) => {
  return axios.delete(`/admin/invites/${inviteId}/companies/${companyId}`);
};

export const redeemInviteTokenRequest = (token: string, inviteToken: string) => {
  return axios.get(`/admin/invites/redeem_token/${inviteToken}`);
};

export const getFullInviteListRequest = (data: GetInvitesRequest) => {
  return axios.post(`/admin/invites/search`, data);
};
