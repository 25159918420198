import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  getTeamsSyncSettingsRequest,
  updateTeamsSyncSettingsRequest,
} from '@vyce/core/src/api/legend/teams';
import { TeamsPageContext } from '@vyce/core/src/contexts';
import { getUrlItems } from '@vyce/core/src/utils/url';

export const useTeamSettingsData = () => {
  const { team } = useParams<{ team: string }>();
  const { selectedCompanyId = '' } = useContext(TeamsPageContext);

  const teamUrlItems = getUrlItems(team);
  const teamId = teamUrlItems?.id;

  return {
    teamId,
    selectedCompanyId,
    updateTeamsSyncSettingsRequest,
    getTeamsSyncSettingsRequest,
  };
};
