import React from 'react';

import { TimeLogsByPeriod } from '@vyce/core/src/views/time/TimeLogsByPeriod';

import { useTypedSelector } from '../../hooks';

export const LegendTimeLogsByPeriod: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return <TimeLogsByPeriod companyId={selectedCompany?.uuid} />;
};
