import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';

import { AppSearchInput } from '@vyce/core/src/components/inputs/AppSearchInput';
import {
  deleteEmailTemplateRequest,
  getEmailTemplatesRequest,
  sendEmailRequest,
  sendEmailToMeRequest,
} from '@vyce/core/src/api/legend/notifications';
import { EmailTemplate, User } from '@vyce/core/src/types';
import { AppCheckboxRow } from '@vyce/core/src/components/AppCheckboxRow';
import { AppIconButton } from '@vyce/core/src/components/AppIconButton';
import { ConfirmDialog } from '@vyce/core/src/components/ConfirmDialog';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import useStyles from '../styles';
import { useTypedSelector } from '../../../hooks';
import { AutocompleteUser, SearchUserAutocomplete } from './SearchUserAutocomplete';
import { CreateEmailTemplateDialog } from './CreateEmailTemplateDialog';
import { SendEmailToMeDialog, SendToMeData } from './SendEmailToMeDialog';

export const Emails: React.FC = () => {
  const classes = useStyles();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { access_token } = useTypedSelector(state => state.helper);
  const [substring, setSubstring] = useState<string>('');
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate>();
  const [templateToDelete, setTemplateToDelete] = useState<EmailTemplate>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openSendToMeDialog, setOpenSendToMeDialog] = useState<boolean>(false);

  const getEmailTemplates = async (substring: string) => {
    try {
      const res = await getEmailTemplatesRequest(substring);
      setTemplates(res.data.items);
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendEmailTemplates = async () => {
    if (!selectedTemplate?.uuid || !selectedUsers.length) {
      return;
    }
    try {
      const users: string[] = selectedUsers.map(user => user.uuid);
      await sendEmailRequest({
        users,
        templateId: selectedTemplate.uuid,
      });
      setOpenSuccessDialog(true);
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendEmailTemplateToMe = async ({ email }: SendToMeData) => {
    const selectedUser = selectedUsers[0];
    if (!selectedTemplate?.uuid || !selectedUsers.length || !selectedUser?.uuid) {
      return;
    }
    try {
      await sendEmailToMeRequest({
        token: access_token,
        userId: selectedUser.uuid,
        templateId: selectedTemplate.uuid,
        email,
      });
      setOpenSendToMeDialog(false);
      showNotification({ message: 'Email has been sent.', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteTemplate = async () => {
    if (!templateToDelete?.uuid) {
      return;
    }
    try {
      await deleteEmailTemplateRequest({ token: access_token, templateId: templateToDelete.uuid });
      getEmailTemplates(substring);
      setOpenConfirmDialog(false);
      setTemplateToDelete(undefined);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getEmailTemplates(substring);
  }, []);

  const handleSearchChange = (event: any) => {
    const search = event.target.value;
    setSubstring(search);
    getEmailTemplates(search);
  };

  const handleCheckboxChange = (template: EmailTemplate, checked: boolean) => {
    setSelectedTemplate(checked ? template : undefined);
  };

  const getAutocompleteUsers = (users: User[]): AutocompleteUser[] => {
    return users.map(user => ({
      email: user.email || '',
      first_name: user.first_name,
      last_name: user.last_name,
      uuid: user.uuid,
    }));
  };

  const clearSelections = () => {
    setSelectedUsers([]);
    setSelectedTemplate(undefined);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" marginBottom={3}>
        <Typography variant="subtitle2">Email Templates</Typography>

        <Box display="flex">
          <Button
            onClick={() => setOpen(true)}
            startIcon={<AiOutlinePlus />}
            style={{ borderRadius: 8, marginLeft: 8, height: 45, width: 228 }}
            variant="contained"
            color="primary">
            Add a new template
          </Button>
        </Box>
      </Box>

      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box marginBottom={2}>
              <AppSearchInput
                placeholder="Search"
                isSmall
                iconColor="#A0A3BD"
                isBorder
                expanded
                onChange={debounce(handleSearchChange, 1000)}
              />
            </Box>

            {!!templates.length && (
              <Box className={classes.container}>
                {templates.map((template, index) => (
                  <AppCheckboxRow
                    key={index}
                    last={index === templates.length - 1}
                    handleChange={e => handleCheckboxChange(template, e.target.checked)}
                    label={template.name}
                    checked={selectedTemplate?.uuid === template.uuid}>
                    <Tooltip placement="top" title="Delete">
                      <AppIconButton
                        onClick={() => {
                          setTemplateToDelete(template);
                          setOpenConfirmDialog(true);
                        }}
                        isSmall
                        variant={selectedTemplate?.uuid === template.uuid ? 'primary' : undefined}
                        color="primary">
                        <HiOutlineTrash size="15px" color="#6E7191" />
                      </AppIconButton>
                    </Tooltip>
                  </AppCheckboxRow>
                ))}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <SearchUserAutocomplete
              mode="email"
              onlyUsers
              label="Send this email to..."
              selectedUsers={getAutocompleteUsers(selectedUsers)}
              setSelectedUsers={setSelectedUsers}
            />
          </Grid>
        </Grid>

        <Box marginTop={5} display="flex" height="100%" alignItems="center" justifyContent="space-between">
          <Button
            onClick={() => clearSelections()}
            disabled={!selectedUsers.length}
            style={{ width: 93 }}
            variant="outlined"
            size="small">
            Cancel
          </Button>

          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={!selectedTemplate || selectedUsers.length !== 1}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setOpenSendToMeDialog(true)}
              style={{ width: 150 }}>
              Send to me
            </Button>
            <Button
              disabled={!selectedTemplate || !selectedUsers.length}
              variant="contained"
              color="primary"
              size="small"
              onClick={sendEmailTemplates}
              style={{ width: 150, marginLeft: 16 }}>
              Send
            </Button>
          </Box>
        </Box>
      </Paper>

      <CreateEmailTemplateDialog
        open={open}
        setOpen={setOpen}
        callback={() => getEmailTemplates(substring)}
      />

      {templateToDelete && (
        <ConfirmDialog
          handleClose={() => {
            setOpenConfirmDialog(false);
            setTemplateToDelete(undefined);
          }}
          open={openConfirmDialog}
          confirmText="Delete"
          cancelText="Cancel"
          title={`Are you sure you want to delete ${templateToDelete.name} template?`}
          handleConfirm={deleteTemplate}
        />
      )}

      <ConfirmDialog
        handleClose={() => {
          setOpenSuccessDialog(false);
          clearSelections();
        }}
        open={openSuccessDialog}
        confirmText="Ok"
        cancelText="Close"
        title="All done!"
        subtitle="Your message has been sent."
        handleConfirm={() => {
          setOpenSuccessDialog(false);
          clearSelections();
        }}
      />

      <SendEmailToMeDialog
        open={openSendToMeDialog}
        setOpen={setOpenSendToMeDialog}
        sentToMe={sendEmailTemplateToMe}
      />
    </>
  );
};
