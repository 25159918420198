import React from 'react';

import { BillingInfo } from '@vyce/core/src/views/subscription/BillingInfo';

import { useTypedSelector } from '../../hooks';

export const LegendBillingInfo: React.FC = () => {
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);
  const { email } = useTypedSelector(state => state.user);

  return (
    <BillingInfo emailToVerifyIdentity={email} token={access_token} selectedCompany={selectedCompany} />
  );
};
