import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useEmployeeProfileData } from '@vyce/core/src/modules/userPreviewModule/hooks/useEmployeeProfileData';
import { getRtwRequest } from '@vyce/core/src/api/legend/checkers';
import { getPassportRequest } from '@vyce/core/src/api/legend/documents';
import { downloadHealthAndSafetyPDFRequest } from '@vyce/core/src/api/legend/pay';
import { getUserDataRequest } from '@vyce/core/src/api/legend/users';
import { UserPreview } from '@vyce/core/src/views/preview/user/UserPreview';
import { PageLoading } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';

export const UserPreviewByLegend: React.FC = () => {
  const { userPreviewData, userPreviewDataLoading, rtw, passport, goToEditView } = useEmployeeProfileData({
    getEmployeePreviewRequest: getUserDataRequest,
    getPassportRequest,
    getRtwRequest,
  });
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();

  if (userPreviewDataLoading)
    return (
      <Box height="100%">
        <PageLoading />
      </Box>
    );

  return (
    <Box padding={isMobile ? 1 : 5}>
      {userPreviewData && (
        <UserPreview
          rtw={rtw}
          passport={passport}
          goToEditView={goToEditView}
          timesheetsLink={`${history.location.pathname}/timesheets`}
          paymentsLink={`${history.location.pathname}/payments`}
          agreementsLink={`${history.location.pathname}/agreements`}
          userPreviewData={userPreviewData}
          showHealthAndSafetyPDFRequest={downloadHealthAndSafetyPDFRequest}
        />
      )}
    </Box>
  );
};
