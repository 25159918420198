import React from 'react';
import { GridColDef } from '@mui/x-data-grid';

import { IsVerified } from '@vyce/core/src/components/IsVerified';
import { FilterSection } from '@vyce/core/src/types';

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    editable: true,
    disableColumnMenu: true,
  },
  {
    field: 'group',
    headerName: 'Group',
    width: 200,
    editable: false,
    disableColumnMenu: true,
    type: 'singleSelect',
    valueOptions: ['role', 'qualification', 'skill'],
  },
  {
    field: 'verified',
    headerName: 'Verified',
    width: 150,
    type: 'boolean',
    disableColumnMenu: true,
    valueGetter: params => params.row.verified,
    renderCell: params => <IsVerified verified={params.row.verified} />,
  },
  {
    field: 'is_apprentice',
    headerName: 'Is Apprentice',
    width: 150,
    type: 'boolean',
    disableColumnMenu: true,
    valueGetter: params => params.row.is_apprentice,
    renderCell: params => <IsVerified verified={params.row.is_apprentice} />,
  },
];

export const filtersSections: FilterSection[] = [
  {
    title: 'Tag fields',
    filters: [
      {
        type: 'select',
        multiple: false,
        label: 'Group',
        field: 'group',
        values: ['', 'role', 'qualification', 'skill'],
        defaultValue: '',
      },
      {
        type: 'select',
        multiple: false,
        label: 'Verified',
        field: 'verified',
        values: ['', 'true', 'false'],
        defaultValue: '',
      },
    ],
  },
];
