import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '@vyce/core/src/theme/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    createTagDialog: {
      width: '400px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    mergeTagsDialog: {
      width: '520px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    moduleContainer: {
      padding: '24px 40px',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },

    tagsListColor: {
      color: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark,
    },

    mediumWeight: {
      fontWeight: 500,
    },

    smallText: {
      fontSize: 13,
    },
  })
);
