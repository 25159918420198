import { Dispatch } from 'redux';

import { Company } from '@vyce/core/src/types';
import { getCompaniesRequest } from '@vyce/core/src/api/legend/companies';
import { saveToLS } from '@vyce/core/src/utils/local-storage';

import { ActionType } from '../action-types';
import { HelperAction } from '../actions/helper';
import { store } from '../store';

export const setLoading = (loading: boolean) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_LOADING,
      payload: loading,
    });
  };
};

export const setSelectedCompany = (company: Company) => {
  saveToLS('selectedCompany', company);
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_SELECTED_COMPANY,
      payload: company,
    });
  };
};

export const clearRedirectTo = () => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.CLEAR_REDIRECT_TO,
    });
  };
};

export const setAccessToken = (token: string) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_ACCESS_TOKEN,
      payload: token,
    });
  };
};

export const clearHelpers = () => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.CLEAR_HELPERS,
    });
  };
};

export const fetchCompanies = () => {
  return async (dispatch: Dispatch<HelperAction>) => {
    let token = store.getState().helper.access_token;
    try {
      const res = await getCompaniesRequest(token, { limit: 5000, with_owner: true });
      dispatch({
        type: ActionType.SET_COMPANIES,
        payload: res.data.items || [],
      });
    } catch (e) {
      console.error(e);
    }
  };
};
