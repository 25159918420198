import { StaffManagementTable } from '@vyce/core/src/views/staff-management/components/StaffManagementTable';

import { useModuleData } from './hooks';

export const StaffManagementModule = () => {
  const {
    selectedCompany,
    companies,
    editPermissionRequest,
    getRolesRequest,
    getStaffRequest,
    sendInviteRequest,
  } = useModuleData();

  return (
    <StaffManagementTable
      isLegend
      companies={companies}
      getStaffRequest={getStaffRequest}
      selectedCompanyId={selectedCompany?.uuid || null}
      editPermissionRequest={editPermissionRequest}
      getRolesRequest={getRolesRequest}
      sendInviteRequest={sendInviteRequest}
      unit="admins"
    />
  );
};
