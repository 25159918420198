import React from 'react';
import { EditCardDetails } from '@vyce/core/src/views/subscription/components/EditCardDetails';

import { useTypedSelector } from '../../hooks';

export const LegendEditCardDetails: React.FC = () => {
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);

  return <EditCardDetails selectedCompany={selectedCompany} token={access_token} />;
};
