import React from 'react';

import { ModuleProps } from './types';
import { modes } from './config';
import { GeneralGrid } from './GeneralGrid';
import { PreviewGrid } from './PreviewGrid';

export const RolesModule = (props: ModuleProps) => {
  const isGeneral = props.mode === modes.general;

  if (isGeneral) return <GeneralGrid {...props} />;
  return <PreviewGrid {...props} />;
};
