import { useContext } from 'react';

import { TeamMembers } from '@vyce/core/src/views/teamMembers';
import { TeamMembersModuleProvider, TeamsPageContext } from '@vyce/core/src/contexts';
import { useTeamMembersData } from '@vyce/core/src/modules/teamMembersModule/hooks/useTeamMembersData';
import { getAllTeamMembersRequest } from '@vyce/core/src/api/legend/teams';
import { PageLoading } from '@vyce/core/src/components/PageLoading';

export const TeamMembersModule = () => {
  const { loading: teamsRequestLoading, selectedCompanyId = '', teams = [] } = useContext(TeamsPageContext);

  const hookData = useTeamMembersData({
    companyId: selectedCompanyId,
    getAllTeamMembersRequest,
    teams,
  });

  if (teamsRequestLoading) return <PageLoading />;

  return (
    <TeamMembersModuleProvider value={hookData}>
      <TeamMembers />
    </TeamMembersModuleProvider>
  );
};
