import { axios } from '@vyce/core/src/api';

import { CreatSurveyData } from '../types';
import { Question, Answer } from '../../types';

export const getSurveyAnswers = (uuid: string) => {
  return axios.get(`/admin/surveys/${uuid}`);
};

export const createSurveyRequest = (token: string, data: CreatSurveyData) => {
  return axios.post('/admin/surveys', data);
};

export const deleteSurveyRequest = (token: string, id: string) => {
  return axios.delete(`/admin/surveys/${id}`);
};

export const updateSurveyRequest = (token: string, id: string, data: CreatSurveyData) => {
  return axios.patch(`/admin/surveys/${id}`, data);
};

export const deleteQuestionRequest = (token: string, surveyId: string, questionId: string) => {
  return axios.delete(`/admin/surveys/${surveyId}/questions/${questionId}`);
};

export const addQuestionRequest = (token: string, surveyId: string, question: Question) => {
  return axios.post(`/admin/surveys/${surveyId}/questions`, question);
};

export const saveSurveyAnswers = (uuid: string, data: Answer[], userId: string) => {
  return axios.post(`/admin/surveys/${uuid}/users/${userId}`, data);
};
