import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { CommonNavBarBlock } from '@vyce/core/src/components';

import { EMAILS_AND_SMS_TABS } from '../main/constants';
import { Emails } from './components/Emails';
import { SMS } from './components/SMS';

export const EmailsAndSMS: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box>
      <CommonNavBarBlock tabItems={EMAILS_AND_SMS_TABS} />

      <Box padding={isMobile ? 1 : 5} paddingTop={isMobile ? 1 : 3}>
        <Switch>
          <Route path={'/emails-and-sms/email'} component={Emails} />
          <Route path={'/emails-and-sms/sms'} component={SMS} />
        </Switch>
      </Box>
    </Box>
  );
};
