import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { PageLoading } from '@vyce/core/src/components/PageLoading';
import { ConfirmDialog } from '@vyce/core/src/components/ConfirmDialog';
import { AdaptiveSearch } from '@vyce/core/src/components';
import { useLocationMembersData } from '@vyce/core/src/modules/timeModule/hooks/useLocationMembersData';
import { useLocationData } from '@vyce/core/src/modules/timeModule/hooks/useLocationData';
import { EditLocation } from '@vyce/core/src/views/time/LocationPage/EditLocation';
import { LocationPreview } from '@vyce/core/src/views/time/LocationPage/LocationPreview';
import { LocationWorkers } from '@vyce/core/src/views/time/components/LocationWorkers';
import { AddSquadMemberButton } from '@vyce/core/src/views/time/LocationPage/components/AddSquadMemberButton';
import { getEmployeeListRequest } from '@vyce/core/src/api/legend/pay';

import { useTypedSelector } from '../../hooks';

export const LocationPage: React.FC = () => {
  const { selectedCompany, access_token } = useTypedSelector(state => state.helper);

  const {
    loading,
    customLocationFields,
    setOpenConfirmDialog,
    editMode,
    location,
    saveLocationChanges,
    paySchedules,
    paySchedule,
    openConfirmDialog,
    closeLocation,
    createShift,
    deleteShift,
  } = useLocationData({ selectedCompany, token: access_token });

  const {
    removeWorker,
    addMember,
    workers,
    handlePageChange,
    handleSearchChange,
    handleSortModelChange,
    total,
    substring,
    sortModel,
    openAddMemberDialog,
    loadingTableData,
    closeGridAction,
    closeAddMemberDialog,
    isAddMemberDialogOpen,
  } = useLocationMembersData({ siteId: location?.uuid, selectedCompany });

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <>
          {location && (
            <>
              {editMode && selectedCompany?.uuid ? (
                <EditLocation
                  companyId={selectedCompany.uuid}
                  createShift={createShift}
                  deleteShift={deleteShift}
                  saveLocationChanges={saveLocationChanges}
                  location={location}
                  paySchedules={paySchedules}
                  handleClose={() => setOpenConfirmDialog(true)}
                  customLocationFields={customLocationFields}
                />
              ) : (
                <LocationPreview
                  handleClose={() => setOpenConfirmDialog(true)}
                  location={location}
                  paySchedule={paySchedule}
                  customLocationFields={customLocationFields}
                />
              )}

              <Box marginTop={5}>
                <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={3}>
                  <Typography variant="h6">Squad Members</Typography>

                  <Box display="flex" gridGap={16}>
                    <AdaptiveSearch
                      variant="paper"
                      searchText={substring || ''}
                      handleSearchChange={handleSearchChange}
                    />

                    {!!location.shifts?.length && (
                      <AddSquadMemberButton
                        openAddMemberDialog={openAddMemberDialog}
                        addMember={addMember}
                        shifts={location.shifts}
                        handleClose={closeAddMemberDialog}
                        open={isAddMemberDialogOpen}
                        getEmployeeListRequest={getEmployeeListRequest}
                        selectedCompany={selectedCompany}
                      />
                    )}
                  </Box>
                </Box>

                <LocationWorkers
                  loading={loadingTableData}
                  total={total}
                  sortModel={sortModel}
                  handleSortModelChange={handleSortModelChange}
                  handlePageChange={handlePageChange}
                  closeGridAction={closeGridAction}
                  removeWorker={removeWorker}
                  workers={workers}
                />
              </Box>
            </>
          )}
        </>
      )}

      <ConfirmDialog
        handleClose={() => setOpenConfirmDialog(false)}
        open={openConfirmDialog}
        confirmText="Close Location"
        cancelText="Cancel"
        title="Do you want to close this Location?"
        subtitle="This means that no-one will be able to clock in or clock out of this location going forward."
        handleConfirm={() => closeLocation()}
      />
    </>
  );
};
