import {
  downloadPayrunBACSRequest,
  downloadPayrunsBACSRequest,
  getPayrunAsFileRequest,
  getPayrunRequest,
  markPayrunAsPaidForEmployeeRequest,
  markPayrunAsPaidRequest,
  refreshPayrunRequest,
  sendPayslipsByEmailRequest,
  sendPayslipsBySMSRequest,
  sendPayslipsToEmployeesByEmailRequest,
  sendPayslipsToEmployeesBySMSRequest,
} from '@vyce/core/src/api/legend/pay';
import { sendPassportRequest, sendShareCodeRequest } from '@vyce/core/src/api/legend/notifications';
import React from 'react';
import { Payrun } from '@vyce/core/src/views/payroll/Payrun';

import { useTypedSelector } from '../../hooks/useTypedSelector';

export const PayrunPage: React.FC = () => {
  const { access_token } = useTypedSelector(state => state.helper);

  return (
    <Payrun
      sendPayslipsToEmployeesBySMSRequest={sendPayslipsToEmployeesBySMSRequest}
      sendPayslipsToEmployeesByEmailRequest={sendPayslipsToEmployeesByEmailRequest}
      token={access_token}
      sendPassportRequest={sendPassportRequest}
      sendShareCodeRequest={sendShareCodeRequest}
      sendPayslipsBySMSRequest={sendPayslipsBySMSRequest}
      sendPayslipsByEmailRequest={sendPayslipsByEmailRequest}
      markPayrunAsPaidForEmployeeRequest={markPayrunAsPaidForEmployeeRequest}
      getPayrunAsFileRequest={getPayrunAsFileRequest}
      getPayrunRequest={getPayrunRequest}
      downloadPayrunBACSRequest={downloadPayrunBACSRequest}
      downloadPayrunsBACSRequest={downloadPayrunsBACSRequest}
      refreshPayrunRequest={refreshPayrunRequest}
      markPayrunAsPaidRequest={markPayrunAsPaidRequest}
    />
  );
};
