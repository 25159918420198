import { CompanyTeamsSortingOption } from '@vyce/core/src/api/types';
import { TabItem } from '@vyce/core/src/types';
import config from '@vyce/core/src/api/config';

export const DEFAULT_TEAMS_LIST_SORTING = {
  field_name: 'name' as CompanyTeamsSortingOption,
  desc: true,
};

export const TEAMS_TABS: TabItem[] = [
  {
    label: 'Dashboard',
    link: '/teams/dashboard',
  },
  {
    label: 'Team Members',
    link: '/teams/members',
  },
  {
    label: 'Teams',
    link: '/teams/list',
  },
];
