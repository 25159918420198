import { axios } from '@vyce/core/src/api';

import { Image } from '../../types';
import {
  ChangeTeamManagersData,
  CreateTeamData,
  GetCompanyTeamsMembersDataRequest,
  GetCompanyTeamsRequest,
  DeleteMemberRequestDTO,
  AddMemberRequestDTO,
  TeamsSyncSettingsDTO,
  GetSyncSettingsData,
} from '../types';

const generateLogoData = (logo: Image): FormData => {
  const bodyFormData = new FormData();

  if (logo?.file) {
    bodyFormData.append('file', logo.file);
  }

  return bodyFormData;
};

export const getCompanyTeamsRequest = ({ companyId, requestData }: GetCompanyTeamsRequest) => {
  return axios.post(`/admin/teams/companies/${companyId}/search`, requestData);
};

export const createTeamsRequest = (token: string, companyId: string, data: CreateTeamData) => {
  return axios.post(`/admin/teams/companies/${companyId}`, data);
};

export const updateLogoRequest = (companyId: string, team_id: string, logo: Image) => {
  const data = generateLogoData(logo);
  return axios.post(`/admin/teams/companies/${companyId}/teams/${team_id}/logo`, data);
};

export const deleteLogoRequest = (companyId: string, team_id: string) => {
  return axios.delete(`/admin/teams/companies/${companyId}/teams/${team_id}/logo`);
};

export const getTeamRequest = (companyId: string, teamId: string) => {
  return axios.get(`/admin/teams/companies/${companyId}/teams/${teamId}`);
};

export const updateTeamRequest = (companyId: string, teamId: string, data: CreateTeamData) => {
  return axios.patch(`/admin/teams/companies/${companyId}/teams/${teamId}`, data);
};

export const deleteTeamRequest = (companyId: string, teamId: string) => {
  return axios.delete(`/admin/teams/companies/${companyId}/teams/${teamId}`);
};

export const getTeamMembersRequest = (
  companyId: string,
  teamId: string,
  requestData: GetCompanyTeamsMembersDataRequest
) => {
  return axios.post(`/admin/teams/companies/${companyId}/teams/${teamId}/members/search`, requestData);
};

export const addTeamMembersRequest = (companyId: string, teamId: string, userIds: AddMemberRequestDTO) => {
  return axios.post(`/admin/teams/companies/${companyId}/teams/${teamId}/members`, userIds);
};

export const deleteTeamMembersRequest = (
  companyId: string,
  teamId: string,
  data: DeleteMemberRequestDTO
) => {
  return axios.delete(`/admin/teams/companies/${companyId}/teams/${teamId}/members`);
};

export const changeTeamManagersRequest = (
  companyId: string,
  teamId: string,
  managers: ChangeTeamManagersData
) => {
  return axios.post(`/admin/teams/companies/${companyId}/teams/${teamId}/managers`, managers);
};

export const getNonMembersRequest = (
  companyId: string,
  teamId: string,
  requestData: GetCompanyTeamsMembersDataRequest
) => {
  return axios.post(`/admin/teams/companies/${companyId}/teams/${teamId}/candidates/search`, requestData);
};

export const addAllEmployeesRequest = (token: string, companyId: string, teamId: string) => {
  return axios.post(`/admin/teams/companies/${companyId}/teams/${teamId}/members/add_all_employees`);
};

export const getAllTeamMembersRequest = (
  companyId: string,
  requestData: GetCompanyTeamsMembersDataRequest
) => {
  return axios.post(`/admin/teams/companies/${companyId}/teams/members/search`, requestData);
};

export const getTeamsSyncSettingsRequest = ({ companyId, teamId }: GetSyncSettingsData) => {
  return axios.get<TeamsSyncSettingsDTO>(
    `/admin/teams/companies/${companyId}/teams/${teamId}/sync_settings`
  );
};

export const updateTeamsSyncSettingsRequest = ({
  companyId,
  teamId,
  payload,
}: GetSyncSettingsData & {
  payload: TeamsSyncSettingsDTO;
}) => {
  return axios.post<TeamsSyncSettingsDTO>(
    `/admin/teams/companies/${companyId}/teams/${teamId}/sync_settings`,
    payload
  );
};
