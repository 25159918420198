import { useCallback, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { createTeamsRequest, updateLogoRequest } from '@vyce/core/src/api/legend/teams';
import { CreateTeamData } from '@vyce/core/src/api/types';
import { TeamsPageContext } from '@vyce/core/src/contexts';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { VERIFY_EMAIL_ERROR_OBJECT } from '@vyce/core/src/constants';

import { useTypedSelector } from '../../../hooks';

const defaultPath = '/teams/list';

export const useCreateTeam = () => {
  const [isOpenTeamDialog, setOpenTeamDialog] = useState<boolean>(false);
  const [needToReset, setNeedToReset] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenNonSubscribedDialog, setOpenNonSubscribedDialog] = useState(false);
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);
  const { email_verified, phone_verified, first_name } = useTypedSelector(state => state.user);
  const { handleServerError, showNotification } = useContext(NotificationContext);

  const history = useHistory();
  const {
    getTeams,
    selectedCompanyId = '',
    selectedCompanyIsSubscribed = false,
  } = useContext(TeamsPageContext);

  const createTeam = useCallback(
    async ({
      formData,
      needToRedirect = false,
    }: {
      formData: CreateTeamData | null;
      needToRedirect?: boolean;
    }) => {
      if (!(selectedCompanyId && formData)) {
        return;
      }
      setIsLoading(true);
      try {
        const { logo, ...restData } = formData;
        const { data } = await createTeamsRequest(access_token, selectedCompanyId, restData);
        if (logo) {
          await updateLogoRequest(selectedCompanyId, data?.uuid, logo);
        }
        getTeams(0);
        setOpenTeamDialog(false);
        setIsLoading(false);
        setNeedToReset(true);
        needToRedirect && history.push(defaultPath);
      } catch (e) {
        handleServerError(e);
        setIsLoading(false);
      }
    },
    [selectedCompanyId, access_token]
  );

  const finishCreateTeam = (data: CreateTeamData) => {
    if (!selectedCompanyIsSubscribed) {
      setOpenNonSubscribedDialog(true);
      return;
    }
    createTeam({ formData: data, needToRedirect: true });
  };

  const handleCloseNonSubscribedDialog = useCallback(() => {
    setOpenNonSubscribedDialog(false);
  }, []);

  const openTeamDialog = useCallback(() => {
    if (!email_verified && !phone_verified) {
      return showNotification(VERIFY_EMAIL_ERROR_OBJECT);
    }
    setOpenTeamDialog(true);
  }, [email_verified, phone_verified]);

  return {
    isLoading,
    isOpenTeamDialog,
    needToReset,
    first_name,
    isOpenNonSubscribedDialog,
    wasSubscribed: selectedCompany?.subscribed_company,
    createTeam,
    finishCreateTeam,
    openTeamDialog,
    setOpenTeamDialog,
    handleCloseNonSubscribedDialog,
  };
};
