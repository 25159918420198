import React, { useState } from 'react';
import { Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { AppIconButton } from '@vyce/core/src/components/AppIconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useStyles from '../styles';

interface Props {
  handleEdit: Function;
  handleRename: Function;
  handleDelete: Function;
  checked: boolean;
  forbiddenToDelete?: boolean;
}

export const SMSEditMenu: React.FC<Props> = ({
  handleEdit,
  checked,
  handleRename,
  handleDelete,
  forbiddenToDelete,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <>
      <AppIconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        isSmall
        disableRipple
        disableTouchRipple
        disableFocusRipple
        variant={checked ? 'primary' : undefined}>
        <BiDotsVerticalRounded size="18px" />
      </AppIconButton>

      <Popper
        style={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper variant="outlined">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList classes={{ root: classes.menuList }} autoFocusItem={open} id="menu-list-grow">
                  <MenuItem
                    onClick={e => {
                      handleEdit();
                      handleClose(e);
                    }}>
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={e => {
                      handleRename();
                      handleClose(e);
                    }}>
                    Rename
                  </MenuItem>
                  {!forbiddenToDelete && (
                    <MenuItem
                      onClick={e => {
                        handleDelete();
                        handleClose(e);
                      }}>
                      Delete
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
