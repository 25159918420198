import React, { useContext } from 'react';

import { Box } from '@material-ui/core';

import { CompanyPreview } from '@vyce/core/src/views/preview/company/CompanyPreview';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';

import { useTypedSelector, useActions } from '../../../hooks';

export const CompanyPreviewWrapper: React.FC = () => {
  const { access_token } = useTypedSelector(state => state.helper);
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box padding={isMobile ? 1 : 5} height="100%">
      <CompanyPreview editable={true} token={access_token} />
    </Box>
  );
};
