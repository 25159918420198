import React from 'react';

import { GridColDef } from '@mui/x-data-grid';

import { FilterSection } from '@vyce/core/src/types';
import { DOCUMENT_STATUSES_ARR, RTW_VALUES } from '@vyce/core/src/constants';

import { AppA, IDStatus, RTWStatus, GridCellWithAvatar } from '@vyce/core/src/components';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { formatTableDate } from '@vyce/core/src/utils/dates';

export const filtersSections: FilterSection[] = [
  {
    title: 'Checks',
    expanded: true,
    filters: [
      {
        type: 'select',
        multiple: false,
        label: 'ID Verified',
        field: 'id_verified',
        values: ['', 'Verified', 'Not Verified'],
        defaultValue: '',
      },
      {
        type: 'select',
        multiple: false,
        label: 'ID Status',
        field: 'id_status',
        values: ['', ...DOCUMENT_STATUSES_ARR],
        defaultValue: '',
      },
      {
        type: 'select',
        multiple: false,
        label: 'RTW Status',
        field: 'rtw_status',
        values: [
          '',
          { name: 'Indefinite', value: RTW_VALUES.INDEFINITE },
          { name: 'Limited', value: RTW_VALUES.LIMITED },
          { name: 'No RTW', value: RTW_VALUES.NO_RTW },
        ],
        defaultValue: '',
      },
    ],
  },
];

export const columns: GridColDef[] = [
  {
    field: 'first_name',
    headerName: 'Name',
    flex: 2,
    minWidth: 150,
    renderCell: params => (
      <GridCellWithAvatar
        avatarUrl={params.row.photo}
        avatarPlaceholder={getAvatar(params.row.gender)}
        name={`${params.row.first_name} ${params.row.last_name}`}
        link={`/user-management/users/${params.row?.first_name}_${params.row?.uuid}`}
      />
    ),
  },
  {
    field: 'ni_number',
    headerName: 'Ref #',
    flex: 1,
    minWidth: 120,
    disableColumnMenu: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 2,
    minWidth: 270,
    renderCell: params => <AppA content={params.row.email} href={`mailto: ${params.row.email}`} />,
  },
  {
    field: 'phone',
    headerName: 'Phone Number',
    minWidth: 180,
    renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
  },
  {
    field: 'id_verified',
    headerName: 'ID verified',
    flex: 1,
    renderCell: params => (
      <IDStatus
        idExpiring={params.row.id_expiring}
        verified={params.row.id_verified}
        status={params.row.id_status}
      />
    ),
    disableColumnMenu: true,
    minWidth: 145,
  },
  {
    field: 'rtw_verified',
    headerName: 'RTW',
    flex: 1,
    renderCell: params => (
      <RTWStatus idExpiring={params.row.rtw_expiring} verified={params.row.rtw_verified} />
    ),
    disableColumnMenu: true,
    minWidth: 145,
  },
  {
    field: 'created_at',
    headerName: 'Registration Date',
    flex: 1,
    disableColumnMenu: true,
    valueGetter: params => formatTableDate(params.row?.created_at),
    minWidth: 120,
  },
];
