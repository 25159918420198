import React, { useContext, useEffect } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { TemplateData } from './SMS';

interface Props {
  open: boolean;
  setOpen: Function;
  title: string;
  subtitle?: string;
  handleSave: (data: TemplateData) => void;
  oldName?: string;
}

export const SaveSMSTemplateDialog: React.FC<Props> = ({
  open,
  setOpen,
  title,
  handleSave,
  oldName = '',
  subtitle,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<TemplateData>({
    defaultValues: {
      name: oldName,
    },
  });
  const {
    control,
    formState: { errors },
    reset,
    setValue,
  } = methods;

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  useEffect(() => {
    if (oldName && open) {
      setValue('name', oldName);
    }
  }, [open]);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSave)}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <Typography variant="subtitle1" color="textSecondary">
              {subtitle}
            </Typography>
            <Controller
              control={control}
              rules={{
                required: 'Name is required',
              }}
              name="name"
              render={({ field }) => (
                <AppTextField
                  label="Name"
                  margin="normal"
                  fullWidth
                  {...field}
                  error={!!errors?.name?.message}
                  helperText={errors?.name?.message || ''}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button size="large" style={{ width: 106 }} onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button size="large" color="primary" style={{ width: 106 }} type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
