import React, { useContext } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { ControlledSelect } from '@vyce/core/src/components/controlled-inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { AppTextField } from '@vyce/core/src/components/inputs';
import { ButtonTitleWithLoading } from '@vyce/core/src/components';

import useStyles from '../styles';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { CreateTagFromVales } from '../types';

const nameRules = { required: 'Name is required' };
const groupRules = { required: 'Group is required' };

interface Props {
  open: boolean;
  groups: string[];
  loading: boolean;
  handleClose: () => void;
  handleCreateNewTag: (data: CreateTagFromVales) => void;
}

export const CreateTagDialog: React.FC<Props> = ({
  open,
  groups,
  loading,
  handleClose,
  handleCreateNewTag,
}) => {
  const classes = useStyles();
  const methods = useForm<CreateTagFromVales>({
    defaultValues: {
      name: '',
      group: groups[0],
    },
  });
  const {
    control,
    formState: { errors },
  } = methods;
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      classes={{ paper: classes.createTagDialog }}
      open={open}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      onClose={handleClose}
      aria-labelledby="create-tag-dialog-title">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleCreateNewTag)}>
          <DialogTitle id="create-tag-dialog-title">Create Tag</DialogTitle>
          <DialogContent>
            <Controller
              control={control}
              name="name"
              rules={nameRules}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  error={!!errors.name?.message}
                  margin="normal"
                  fullWidth
                  autoFocus
                  label="Name"
                  type="text"
                  id="name"
                  autoComplete="current-password"
                  helperText={errors.name?.message || ''}
                />
              )}
            />
            <ControlledSelect
              rules={groupRules}
              label="Group"
              name="group"
              margin="normal"
              items={groups}
              multiple={false}
            />
          </DialogContent>
          <DialogActions>
            <Button size="large" variant="outlined" disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              style={{ width: 100 }}
              size="large"
              disabled={loading}
              type="submit"
              color="primary"
              variant="contained">
              <ButtonTitleWithLoading title="Create" loaderVariant="paper" loading={loading} />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
