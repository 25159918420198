import React, { useContext } from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import useStyles from '../../styles';

interface Props {
  open: boolean;
  handleDialogClose: Function;
  deleteSurvey: Function;
}

export const DeleteWarningDialog: React.FC<Props> = ({ open, handleDialogClose, deleteSurvey }) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      classes={{ paper: classes.createSurveyDialog }}
      onClose={() => handleDialogClose()}
      open={open}>
      <DialogTitle>We'll lose all users answers! Are you sure?</DialogTitle>

      <DialogActions>
        <Button size="large" onClick={() => handleDialogClose()} color="primary" variant="contained">
          No, take me back
        </Button>
        <Button
          size="large"
          onClick={() => deleteSurvey()}
          className={classes.deleteButton}
          variant="outlined">
          Ok, Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
