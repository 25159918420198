import React, { useContext, useEffect, useRef, useState } from 'react';

import noop from 'lodash/noop';
import { Box } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import { BACSFormat, HMRCSetting } from '@vyce/core/src/types';
import { DeviceContext, PayModuleProvider } from '@vyce/core/src/contexts';
import {
  companyRegistrationRequest,
  createPayScheduleRequest,
  getBACSFormatsRequest,
  getHMRCSettingsByCompanyRequest,
} from '@vyce/core/src/api/legend/pay';
import { CommonNavBarBlock } from '@vyce/core/src/components';
import { PayrollOnboarding } from '@vyce/core/src/views/payroll/PayrollOnboarding';
import { LEGEND_PAYROLL_TABS } from '@vyce/core/src/constants';
import { getPaddingForContent } from '@vyce/core/src/utils';

import { LegendTimesheets } from './components/LegendTimesheets';
import { LegendTimesheetPage } from './LegendTimesheetPage';
import { LegendPayruns } from './components/LegendPayruns';
import { PayrunPage } from './LegendPayrunPage';
import { LegendReports } from './LegendReports';
import { LegendPaySchedules } from './components/LegendPaySchedules';
import { LegendPaySchedulePage } from './LegendPaySchedulePage';
import { LegendEmployeeList } from './components/LegendEmployeeList';
import { useTypedSelector } from '../../hooks';
import { LegendCompanyFilter } from '../main/components/LegendCompanyFilter';

export const LegendPayroll: React.FC = () => {
  const { access_token, companies, selectedCompany } = useTypedSelector(state => state.helper);
  const { user } = useTypedSelector(state => state);
  const mountedRef = useRef(true);
  const [bacsFormats, setBacsFormats] = useState<BACSFormat[]>();
  const [hmrcSettings, setHMRCSettings] = useState<HMRCSetting>();
  const { isMobile } = useContext(DeviceContext);
  const padding = getPaddingForContent(isMobile);

  useEffect(() => {
    if (!mountedRef.current) return;
    getBacsFormats();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedCompany?.uuid) {
      getHMRCSettings(selectedCompany.uuid);
    }
  }, [selectedCompany?.uuid]);

  const getHMRCSettings = async (companyId: string) => {
    try {
      const res = await getHMRCSettingsByCompanyRequest(companyId);
      setHMRCSettings(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getBacsFormats = async () => {
    try {
      const res = await getBACSFormatsRequest();
      if (!mountedRef.current) return;
      setBacsFormats(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box>
      <CommonNavBarBlock tabItems={LEGEND_PAYROLL_TABS}>
        <LegendCompanyFilter />
      </CommonNavBarBlock>

      <Box padding={padding}>
        <PayModuleProvider
          value={{ companies, bacsFormats, hmrcSettings, selectedCompanyId: selectedCompany?.uuid }}>
          <Switch>
            <Route exact path={'/payroll/timesheets/:id'} component={LegendTimesheetPage} />
            <Route exact path={'/payroll/timesheets'} component={LegendTimesheets} />
            <Route exact path={'/payroll/payruns/:id'} component={PayrunPage} />
            <Route exact path={'/payroll/payruns'} component={LegendPayruns} />
            <Route exact path={'/payroll/users'} component={LegendEmployeeList} />
            <Route exact path={'/payroll/reports'} component={LegendReports} />
            <Route exact path={'/payroll/schedules'} component={LegendPaySchedules} />
            <Route
              exact
              path={'/payroll/schedules/create-pay-schedule'}
              component={() => (
                <PayrollOnboarding
                  user={user}
                  token={access_token}
                  createPayScheduleRequest={createPayScheduleRequest}
                  companyRegistrationRequest={companyRegistrationRequest}
                  subscribed={true}
                  // TODO: add proper request when backend is ready
                  uploadPayScheduleContractWrapperRequest={(() => {}) as any}
                  updateSelectedCompanySubscription={noop}
                />
              )}
            />
            <Route exact path={'/payroll/schedules/:schedule'} component={LegendPaySchedulePage} />
          </Switch>
        </PayModuleProvider>
      </Box>
    </Box>
  );
};
