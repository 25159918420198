import React from 'react';

import { LocationsAndShifts } from '@vyce/core/src/views/time/LocationsAndShifts';

import { useTypedSelector } from '../../hooks';

export const LegendLocationsAndShifts: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return <LocationsAndShifts selectedCompany={selectedCompany} />;
};
