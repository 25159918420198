import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';

import { fetchUsersRequest } from '@vyce/core/src/api/legend/users';
import { User } from '@vyce/core/src/types';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { GetUsersData } from '@vyce/core/src/api/types';
import { useDebounceValue } from '@vyce/core/src/hooks/useDebounceValue';
import { useQuery } from '@vyce/core/src/hooks/useQuery';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { getBooleanFilterValue, isNil } from '@vyce/core/src/utils';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useTable } from '@vyce/core/src/hooks/useTable';

const defaultSortModel: GridSortModel = [{ field: 'created_at', sort: 'desc' }];

export const useModuleData = () => {
  const query = useQuery();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [filters, setFilters] = useState<any>();
  const { handleServerError } = useContext(NotificationContext);
  const {
    sortModel,
    offset,
    substring,
    total,
    setTotal,
    setOffset,
    setSubstring,
    handleSortModelChange,
    handlePageChange,
  } = useTable({ defaultSortModel, initialSubstring: query.get('search') || '' });

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const history = useHistory();

  const getUsers = useCallback(async () => {
    if (isNil(dOffset)) return;

    try {
      const res = await fetchUsersRequest({
        offset: dOffset,
        limit: GRID_PAGE_SIZE,
        substring,
        order_by: formatSortModel<User>(sortModel),
        ...filters,
      });
      setUsers(res.data.items);
      setTotal(res.data.count);
      setLoading(false);
    } catch (e: any) {
      handleServerError(e);
      setLoading(false);
    }
  }, [dOffset, filters, sortModel, substring]);

  const handleAddMemberClick = () => {
    history.push(`/add-new-member`);
  };

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSubstring(e.target.value);
    history.push(`${history.location.pathname}?search=${e.target.value}`);
  }, []);

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 500), [handleSearchChange]);

  const handleFilterChange = (newFilters: any) => {
    const formattedFilters = prepareFilters(newFilters);
    setFilters(formattedFilters);
  };

  const prepareFilters = (filters: any): GetUsersData => {
    return {
      id_verified: getBooleanFilterValue(filters.id_verified),
      rtw_status: filters.rtw_status?.value,
      id_status: filters.id_status,
    };
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUsers();
    }
    return () => {
      mounted = false;
    };
  }, [getUsers]);

  return {
    substring,
    users,
    total,
    loading,
    sortModel,
    handleFilterChange,
    handlePageChange,
    handleSortModelChange,
    handleSearchChange: debouncedSearchChange,
    handleAddMemberClick,
    setOffset,
  };
};
