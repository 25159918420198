import { RolesModule } from '@vyce/core/src/modules';

import { LegendInviteButton } from '../../components';
import { useModuleData } from './hooks';

export const RolesAndPermissionModule = () => {
  const { selectedCompany } = useModuleData();

  return (
    <RolesModule
      selectedCompanyId={selectedCompany?.uuid}
      inviteButton={LegendInviteButton}
      mode="general"
    />
  );
};
