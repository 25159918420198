import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { Layout, Layouts } from 'react-grid-layout';
import clsx from 'clsx';

import { UserDashboard, WidgetItem } from '@vyce/core/src/types';
import { AppDashboard } from '@vyce/core/src/components/Dashboard/AppDashboard';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { DeviceContext } from '@vyce/core/src/contexts';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { CustomizeDashboard } from '@vyce/core/src/components';
import { WeatherWidget } from '@vyce/core/src/views/widgets/WeatherWidget';
import { DayQuoteWidget } from '@vyce/core/src/views/widgets/DayQuoteWidget';

import { useActions, useTypedSelector } from '../../hooks';
import { WidgetModule } from '../../modules/widgetModule';

const weatherWidget: WidgetItem = {
  name: 'Weather',
  type: 'weather',
  system_name: 'weather',
  layout: { i: 'weather', x: 0, y: 0, w: 4, h: 8, minH: 3, minW: 3, isResizable: false },
  is_active: true,
};

export const LegendDashboard: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);
  const padding = getPaddingForContent(isMobile);
  const { dashboards } = useTypedSelector(state => state.user);
  const horizontalScrollClasses = useHorizontalScrollStyles();
  const [generalDashboard, setGeneralDashboard] = useState<UserDashboard>();
  const { updateUserDashboards } = useActions();

  const update = useCallback(
    debounce(dashboard => {
      if (!dashboard?.layouts || !dashboard?.widgets) {
        return;
      }
      const userDashboards = {
        ...dashboards,
        general: dashboard,
      };
      updateUserDashboards(userDashboards);
    }, 300),
    []
  );

  const onWidgetStatusChange = (systemName: string) => {
    const dashboardWidgets = generalDashboard?.widgets || [];
    const newWidgets: WidgetItem[] = dashboardWidgets.map(item => {
      if (item.system_name === systemName) {
        return { ...item, is_active: !item.is_active };
      }
      return item;
    });
    setGeneralDashboard({ ...generalDashboard, widgets: newWidgets });
  };

  useEffect(() => {
    update(generalDashboard);
  }, [generalDashboard]);

  useEffect(() => {
    setGeneralDashboard({
      ...dashboards?.general,
      widgets: [weatherWidget],
    });
  }, []);

  const onLayoutChange = (currentLayout: Layout[], newLayouts: Layouts) => {
    setGeneralDashboard(value => ({ ...value, layouts: newLayouts }));
  };

  const getWidget = (widget: WidgetItem) => {
    switch (widget.system_name) {
      case 'weather':
        return <WeatherWidget />;
      case 'quote':
        return <DayQuoteWidget />;
      default:
        return <WidgetModule widget={widget} />;
    }
  };

  return (
    <Box padding={padding}>
      <Box
        className={clsx(horizontalScrollClasses.blockWrapper, horizontalScrollClasses.blockWithHideScroll)}>
        {!isMobile && (
          <Box display="flex">
            <Typography
              variant="subtitle1"
              style={{ marginRight: 4, fontWeight: 600, whiteSpace: 'nowrap' }}
              color="secondary">
              Hey Legend,
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
              here’s your dashboard!
            </Typography>
          </Box>
        )}

        <Box>
          <CustomizeDashboard
            items={generalDashboard?.widgets || []}
            onWidgetStatusChange={onWidgetStatusChange}
          />
        </Box>
      </Box>

      <Box style={{ marginRight: '-24px', marginLeft: '-24px' }}>
        <AppDashboard
          layouts={generalDashboard?.layouts}
          onLayoutChange={onLayoutChange}
          widgets={generalDashboard?.widgets || []}
          getWidget={getWidget}
          onWidgetStatusChange={onWidgetStatusChange}
        />
      </Box>
    </Box>
  );
};
