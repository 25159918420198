import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Grid, Paper, Tooltip, Typography, useTheme } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoCalendarOutline, IoSyncOutline } from 'react-icons/io5';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import { BsGear } from 'react-icons/bs';
import { FiUserCheck } from 'react-icons/fi';

import { AppSearchInput } from '@vyce/core/src/components/inputs/AppSearchInput';
import {
  createSMSTemplateRequest,
  deleteSMSTemplateRequest,
  getSMSTemplatesRequest,
  sendCustomSMSRequest,
  sendSMSRequest,
  sendSMSToMeRequest,
  updateSMSTemplateRequest,
} from '@vyce/core/src/api/legend/notifications';
import { SMSTemplate, User } from '@vyce/core/src/types';
import { AppCheckboxRow } from '@vyce/core/src/components/AppCheckboxRow';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { ConfirmDialog } from '@vyce/core/src/components/ConfirmDialog';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import useStyles from '../styles';
import { AutocompleteUser, NOT_REGISTERED_USER_ID, SearchUserAutocomplete } from './SearchUserAutocomplete';
import { useTypedSelector } from '../../../hooks';
import { SaveSMSTemplateDialog } from './SaveSMSTemplateDialog';
import { SMSEditMenu } from './SMSEditMenu';
import { ScheduleMessageDialog } from './ScheduleMessageDialog';
import { SendSMSToMeData, SendSMSToMeDialog } from './SendSMSToMeDialog';

export interface TemplateData {
  name: string;
}

export const SMS: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { access_token } = useTypedSelector(state => state.helper);
  const [substring, setSubstring] = useState<string>('');
  const [templates, setTemplates] = useState<SMSTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<SMSTemplate>();
  const [templateForAction, setTemplateForAction] = useState<SMSTemplate>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [templateToEdit, setTemplateToEdit] = useState<SMSTemplate>();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
  const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openScheduleDialog, setOpenScheduleDialog] = useState<boolean>(false);
  const [openSendToMeDialog, setOpenSendToMeDialog] = useState<boolean>(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');

  const getSMSTemplates = async (substring: string) => {
    try {
      const res = await getSMSTemplatesRequest(substring);
      setTemplates(res.data.items);
    } catch (e) {
      handleServerError(e);
    }
  };

  const saveSMSTemplate = async (data: { name: string }) => {
    try {
      await createSMSTemplateRequest({ token: access_token, data: { ...data, text } });
      showNotification({ message: 'Template has been created.', options: { variant: 'success' } });
      getSMSTemplates(substring);
      setOpenSaveDialog(false);
      setText('');
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendSMSTemplateToMe = async ({ phone }: SendSMSToMeData) => {
    const selectedUser = selectedUsers[0];
    if (!selectedTemplate?.uuid || !selectedUsers.length || !selectedUser?.uuid) {
      return;
    }
    try {
      await sendSMSToMeRequest({
        token: access_token,
        userId: selectedUser.uuid,
        templateId: selectedTemplate.uuid,
        phone,
      });
      setOpenSendToMeDialog(false);
      showNotification({ message: 'Email has been sent.', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteSMSTemplate = async () => {
    if (!templateForAction?.uuid) {
      return;
    }
    try {
      await deleteSMSTemplateRequest({ token: access_token, templateId: templateForAction.uuid });
      showNotification({ message: 'Template has been deleted.', options: { variant: 'success' } });
      getSMSTemplates(substring);
      setOpenDeleteDialog(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const updateSMSTemplate = async (data: TemplateData) => {
    if (!templateForAction?.uuid && !templateToEdit?.uuid) {
      return;
    }
    try {
      await updateSMSTemplateRequest({
        token: access_token,
        data: { ...data, text },
        templateId: templateToEdit?.uuid ? templateToEdit.uuid : templateForAction?.uuid || '',
      });
      showNotification({ message: 'Template has been updated.', options: { variant: 'success' } });
      getSMSTemplates(substring);
      setOpenRenameDialog(false);
      setOpenEditDialog(false);
      setTemplateToEdit(undefined);
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendSMS = async (scheduled?: string) => {
    try {
      const users: string[] = [];
      const phones: string[] = [];
      selectedUsers.forEach(user => {
        if (!user.uuid.includes(NOT_REGISTERED_USER_ID)) {
          users.push(user.uuid);
        } else if (user.phone) {
          phones.push(user.phone);
        }
      });
      if (selectedTemplate?.uuid) {
        await sendSMSRequest({
          users,
          templateId: selectedTemplate?.uuid,
          scheduled,
          phones,
        });
      } else {
        await sendCustomSMSRequest({
          token: access_token,
          users,
          scheduled,
          phones,
          message: text,
        });
      }
      const message = scheduled
        ? `All done! Your message will be sent on ${dayjs(scheduled).format('DD/MM/YYYY h:mm A')}.`
        : 'SMS has been sent.';
      setSuccessMessage(message);
      setOpenSuccessDialog(true);
      setOpenScheduleDialog(false);
      setOpenConfirmDialog(false);
    } catch (e) {
      console.error(e);
      handleServerError(e);
    }
  };

  useEffect(() => {
    getSMSTemplates(substring);
  }, []);

  const handleSearchChange = (event: any) => {
    const search = event.target.value;
    setSubstring(search);
    getSMSTemplates(search);
  };

  const removeNonRegisteredUsers = () => {
    setSelectedUsers(value => value.filter(user => !user.uuid.includes(NOT_REGISTERED_USER_ID)));
  };

  const handleCheckboxChange = (template: SMSTemplate, checked: boolean) => {
    if (checked) {
      setSelectedTemplate(template);
      removeNonRegisteredUsers();
      setText(template.text);
    } else {
      setSelectedTemplate(undefined);
      setText('');
    }
  };

  const getAutocompleteUsers = (users: User[]): AutocompleteUser[] => {
    return users.map(user => ({
      phone: user.phone || '',
      first_name: user.first_name,
      last_name: user.last_name,
      uuid: user.uuid,
    }));
  };

  const clearSelection = () => {
    setSelectedUsers([]);
    setTemplateToEdit(undefined);
    setSelectedTemplate(undefined);
    setText('');
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" marginBottom={3}>
        <Typography variant="subtitle2">SMS Messages</Typography>

        <Box display="flex">
          <Button
            startIcon={templateToEdit ? <IoSyncOutline /> : <AiOutlinePlus />}
            onClick={() => (templateToEdit ? setOpenEditDialog(true) : setOpenSaveDialog(true))}
            style={{ borderRadius: 8, marginLeft: 8, height: 45, width: 228 }}
            variant="contained"
            disabled={!text}
            color="primary">
            {templateToEdit ? 'Update Template' : 'Save as a new Template'}
          </Button>
        </Box>
      </Box>

      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box marginBottom={2}>
              <AppSearchInput
                placeholder="Search"
                isSmall
                iconColor="#A0A3BD"
                isBorder
                expanded
                onChange={debounce(handleSearchChange, 1000)}
              />
            </Box>

            {!!templates.length && (
              <Box className={classes.container}>
                {templates.map((template, index) => (
                  <AppCheckboxRow
                    key={index}
                    last={index === templates.length - 1}
                    handleChange={e => {
                      handleCheckboxChange(template, e.target.checked);
                      setTemplateToEdit(undefined);
                    }}
                    label={template.name}
                    checked={selectedTemplate?.uuid === template.uuid}>
                    <>
                      {template.system && (
                        <Tooltip title="System template. You are not able to send it to user.">
                          <Box marginRight={2} display="flex" alignItems="center">
                            <BsGear color={theme.palette.text.secondary} />
                          </Box>
                        </Tooltip>
                      )}
                      {template.registered_only && (
                        <Tooltip title="Template contains user data. You are not able to send it to not registered user.">
                          <Box marginRight={2} display="flex" alignItems="center">
                            <FiUserCheck color={theme.palette.text.secondary} />
                          </Box>
                        </Tooltip>
                      )}
                      <SMSEditMenu
                        checked={selectedTemplate?.uuid === template.uuid}
                        forbiddenToDelete={template?.system}
                        handleRename={() => {
                          setOpenRenameDialog(true);
                          setTemplateForAction(template);
                        }}
                        handleDelete={() => {
                          setOpenDeleteDialog(true);
                          setTemplateForAction(template);
                        }}
                        handleEdit={() => {
                          handleCheckboxChange(template, true);
                          setTemplateToEdit(template);
                        }}
                      />
                    </>
                  </AppCheckboxRow>
                ))}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchUserAutocomplete
              mode="phone"
              disabled={selectedTemplate?.system}
              onlyUsers={selectedTemplate?.registered_only}
              label="Send this sms to..."
              selectedUsers={getAutocompleteUsers(selectedUsers)}
              setSelectedUsers={setSelectedUsers}
            />

            <Box marginTop="22px" marginBottom={3} borderBottom={`2px solid ${theme.palette.divider}`} />

            <AppTextField
              multiline
              onChange={e => setText(e.target.value)}
              className={classes.textarea}
              disabled={selectedTemplate && !templateToEdit}
              value={text}
              label="Type your message here."
              fullWidth
            />
          </Grid>
        </Grid>

        <Box marginTop={5} display="flex" height="100%" alignItems="center" justifyContent="space-between">
          <Button
            onClick={() => clearSelection()}
            disabled={!selectedUsers.length && !templateToEdit}
            style={{ width: 93 }}
            variant="outlined"
            size="small">
            Cancel
          </Button>

          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={!text || selectedUsers.length !== 1}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setOpenScheduleDialog(true)}
              startIcon={<IoCalendarOutline />}
              style={{ width: 150 }}>
              Schedule
            </Button>
            <Button
              disabled={!text || selectedUsers.length !== 1 || !selectedTemplate}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setOpenSendToMeDialog(true)}
              style={{ width: 150, marginLeft: 16 }}>
              Send to me
            </Button>
            <Button
              disabled={!text || !selectedUsers.length}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpenConfirmDialog(true)}
              style={{ width: 150, marginLeft: 16 }}>
              Send
            </Button>
          </Box>
        </Box>
      </Paper>

      <SaveSMSTemplateDialog
        open={openSaveDialog}
        handleSave={saveSMSTemplate}
        setOpen={setOpenSaveDialog}
        title="Save this Template"
      />

      <SaveSMSTemplateDialog
        open={openRenameDialog}
        oldName={templateForAction?.name}
        handleSave={updateSMSTemplate}
        setOpen={setOpenRenameDialog}
        title="Rename this Template"
      />

      <SaveSMSTemplateDialog
        open={openEditDialog}
        oldName={templateToEdit?.name}
        handleSave={updateSMSTemplate}
        setOpen={setOpenEditDialog}
        title="Update this Template"
        subtitle="Would you like to rename it?"
      />

      <ConfirmDialog
        handleClose={() => setOpenConfirmDialog(false)}
        open={openConfirmDialog}
        confirmText="Send"
        cancelText="Cancel"
        title="Are you sure you want to send this SMS?"
        handleConfirm={sendSMS}
      />

      <ConfirmDialog
        handleClose={() => setOpenDeleteDialog(false)}
        open={openDeleteDialog}
        confirmText="Yes"
        cancelText="Cancel"
        title="Do you want to delete this SMS Template?"
        handleConfirm={deleteSMSTemplate}
      />

      <ConfirmDialog
        handleClose={() => setOpenDeleteDialog(false)}
        open={openDeleteDialog}
        confirmText="Yes"
        cancelText="Cancel"
        title="Do you want to delete this SMS Template?"
        handleConfirm={deleteSMSTemplate}
      />

      <ConfirmDialog
        handleClose={() => {
          setOpenSuccessDialog(false);
          clearSelection();
        }}
        open={openSuccessDialog}
        confirmText="Ok"
        cancelText="Close"
        title="All Done!"
        subtitle={successMessage}
        handleConfirm={() => {
          setOpenSuccessDialog(false);
          clearSelection();
        }}
      />

      <ScheduleMessageDialog sendSMS={sendSMS} open={openScheduleDialog} setOpen={setOpenScheduleDialog} />

      <SendSMSToMeDialog
        setOpen={setOpenSendToMeDialog}
        open={openSendToMeDialog}
        sentToMe={sendSMSTemplateToMe}
      />
    </>
  );
};
