import React, { useContext } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { ControlledSelect } from '@vyce/core/src/components/controlled-inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { Tag } from '@vyce/core/src/types';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { ButtonTitleWithLoading } from '@vyce/core/src/components';

import useStyles from '../styles';
import { MergeTagsValues } from '../types';

const rules = { required: 'Tag is required' };

interface Props {
  open: boolean;
  loading: boolean;
  tags: Tag[];
  handleClose: () => void;
  handleMergeTags: ({ main_tag_id, tags }: { main_tag_id: string; tags: string[] }) => void;
}

export const MergeTagsDialog = ({ open, loading, tags, handleClose, handleMergeTags }: Props) => {
  const classes = useStyles();
  const methods = useForm<MergeTagsValues>({
    defaultValues: {
      tag: tags[0],
    },
  });
  const { watch } = methods;
  const { isMobile } = useContext(DeviceContext);
  const selectedTag = watch('tag');

  const notIncludedTagsNames = tags
    .filter(item => item.uuid !== selectedTag.uuid)
    .map(item => item.name)
    .join(', ');

  const onSubmit = (data: MergeTagsValues) => {
    const notIncludedTagsIds = tags.filter(item => item.uuid !== selectedTag.uuid).map(item => item.uuid);
    handleMergeTags({ main_tag_id: selectedTag.uuid, tags: notIncludedTagsIds });
  };

  return (
    <Dialog
      classes={{ paper: classes.mergeTagsDialog }}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      fullScreen={isMobile}
      onClose={handleClose}
      aria-labelledby="create-tag-dialog-title">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="create-tag-dialog-title">Merge Tags</DialogTitle>

          <DialogContent>
            <Box display="flex" flexDirection="column" gridGap={16}>
              <Typography className={classes.tagsListColor}>
                Selected tags will all be changed into the main tag. This action cannot be undone.
              </Typography>

              <ControlledSelect rules={rules} label="Main Tag" name="tag" items={tags} />
              <Box display="flex" flexDirection="column">
                <Typography className={classes.smallText}>Tags that will be changed:</Typography>
                <Typography className={clsx(classes.tagsListColor, classes.mediumWeight, classes.smallText)}>
                  {notIncludedTagsNames}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="large" variant="outlined" disabled={loading} onClick={handleClose}>
              Close
            </Button>
            <Button size="large" disabled={loading} type="submit" color="primary" variant="contained">
              <ButtonTitleWithLoading title="Merge" loaderVariant="paper" loading={loading} />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
