import React, { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import { Box, Button, MenuItem, MenuList } from '@material-ui/core';
import type { GridColDef } from '@mui/x-data-grid';
import { AiOutlinePlus } from 'react-icons/ai';
import noop from 'lodash/noop';
import clsx from 'clsx';
import { BiExport } from 'react-icons/bi';

import {
  AppA,
  ButtonTitleWithLoading,
  AppDataGridWithSavedPage,
  FilterSystem,
  GridCellWithAvatar,
  ConfirmDialog,
  GridActions,
  IDStatus,
  RTWStatus,
} from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { TeamMembersModuleContext, TeamsPageContext, DeviceContext } from '@vyce/core/src/contexts';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { getUKFormattedDate } from '@vyce/core/src/utils/dates';

import { UserManagementItem } from '../../types';

export const TeamMembers = () => {
  const horizontalScrollClasses = useHorizontalScrollStyles();
  const {
    loading,
    total,
    teamMembers,
    sortModel,
    filtersSections,
    filters,
    closeGridAction,
    isConfirmDialogOpen,
    userToRemove,
    handleRemoveClick,
    withProfileButton,
    exportButtonLoading,
    setOffset,
    handleFilterChange,
    closeConfirmDialog,
    removeFromModule,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
    exportQualifications,
  } = useContext(TeamMembersModuleContext);
  const { pathname } = useLocation();
  const { isMobile } = useContext(DeviceContext);

  const { inviteButton, onOpenUniversalInviteForm } = useContext(TeamsPageContext);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'first_name',
        headerName: 'Name',
        renderCell: params => (
          <GridCellWithAvatar
            avatarUrl={params.row.avatar}
            avatarPlaceholder={getAvatar(params.row.gender)}
            link={`${pathname}/${params.row?.first_name}_${params.row?.user_id}`}
            name={`${params.row.first_name} ${params.row.last_name}`}
          />
        ),
        flex: 0.2,
        minWidth: 100,
        editable: true,
        disableColumnMenu: true,
      },
      {
        field: 'ni_number',
        headerName: 'Ref #',
        flex: 0.1,
        minWidth: 90,
        disableColumnMenu: true,
      },
      {
        field: 'registration_date',
        headerName: 'Registration Date',
        flex: 0.15,
        minWidth: 120,
        valueGetter: params => getUKFormattedDate(params.row?.registration_date),
        disableColumnMenu: true,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 0.2,
        minWidth: 270,
        disableColumnMenu: true,
        renderCell: params => <AppA content={params.row.email} href={`mailto: ${params.row.email}`} />,
      },
      {
        field: 'phone',
        headerName: 'Phone Number',
        disableColumnMenu: true,
        minWidth: 180,
        renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
      },
      {
        field: 'id_verified',
        headerName: 'ID verified',
        flex: 0.15,
        minWidth: 140,
        renderCell: params => (
          <IDStatus
            idExpiring={params.row.id_expiring}
            verified={params.row.id_verified}
            status={params.row.id_status}
          />
        ),
        disableColumnMenu: true,
      },
      {
        field: 'rtw_verified',
        headerName: 'RTW',
        flex: 0.1,
        minWidth: 50,
        renderCell: params => (
          <RTWStatus idExpiring={params.row.rtw_expiring} verified={params.row.rtw_verified} />
        ),
        disableColumnMenu: true,
      },
      {
        field: '',
        headerName: 'Actions',
        width: 80,
        hideSortIcons: true,
        sortable: false,
        disableColumnMenu: true,
        renderCell: params => (
          <Box display="flex" width="100%">
            <GridActions close={closeGridAction}>
              <MenuList>
                {handleRemoveClick && (
                  <MenuItem
                    cy-test-id="remove-from-team-button"
                    onClick={() => handleRemoveClick(params.row as UserManagementItem)}>
                    Remove from Teams module
                  </MenuItem>
                )}
              </MenuList>
            </GridActions>
          </Box>
        ),
      },
    ],
    [pathname, closeGridAction]
  );

  return (
    <>
      <Box
        mb={2}
        gridGap={16}
        className={clsx(horizontalScrollClasses.blockWrapper, horizontalScrollClasses.blockWithHideScroll)}>
        <Box>
          <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />
        </Box>

        <Box display="flex" gridGap={16}>
          {inviteButton ? (
            React.createElement(inviteButton)
          ) : (
            <Button
              onClick={onOpenUniversalInviteForm}
              size="small"
              data-tour="invite"
              startIcon={<AiOutlinePlus />}
              variant="contained"
              color="primary">
              Invite New Members
            </Button>
          )}

          <Button
            onClick={exportQualifications}
            startIcon={!exportButtonLoading && <BiExport size="15px" />}
            style={{ width: 234 }}
            variant="contained"
            size="small"
            color="secondary">
            <ButtonTitleWithLoading
              title="Export Qualifications Matrix"
              loaderVariant="paper"
              loading={exportButtonLoading}
            />
          </Button>

          {filtersSections && (
            <FilterSystem
              selectedValues={filters}
              filtersSections={filtersSections}
              onFiltersChange={handleFilterChange || noop}
            />
          )}
        </Box>
      </Box>

      <AppDataGridWithSavedPage
        rows={teamMembers}
        getRowId={row => row.user_id}
        loading={loading}
        columns={columns}
        noPaper
        height="calc(100vh - 240px)"
        rowCount={total}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="team members"
        withProfileButton={withProfileButton}
        setOffset={setOffset}
      />

      {closeConfirmDialog && removeFromModule && (
        <ConfirmDialog
          handleClose={closeConfirmDialog}
          open={!!isConfirmDialogOpen}
          confirmText="Remove"
          cancelText="Cancel"
          title={`Are you sure you want to remove ${userToRemove?.first_name} ${userToRemove?.last_name} from Teams module?`}
          handleConfirm={removeFromModule}
          loading={loading}
        />
      )}
    </>
  );
};
