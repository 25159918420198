import { Login } from '@vyce/core/src/views/auth/components/Login';
import React from 'react';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';

export const LegendLogin: React.FC = () => {
  const title = 'Welcome To Vyce';
  const subtitle = 'Please login for awesomeness';
  const { userLogin } = useActions();
  const { userDataLoading } = useTypedSelector(state => state.helper);

  return (
    <Login
      title={title}
      hideSignUpLink={true}
      redirectUrl={'/dashboard'}
      subtitle={subtitle}
      userLogin={userLogin}
      loading={userDataLoading}
    />
  );
};
