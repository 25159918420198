import React from 'react';

import { TimeDashboardModule as CoreTimeDashboardModule } from '@vyce/core/src/modules';
import { TimeDashboardModuleProvider } from '@vyce/core/src/contexts';

import { useTimeDashboardData } from './hooks';

export const TimeDashboardModule = () => {
  const { contextOptions } = useTimeDashboardData();

  return (
    <TimeDashboardModuleProvider value={contextOptions}>
      <CoreTimeDashboardModule />
    </TimeDashboardModuleProvider>
  );
};
