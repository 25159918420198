import { useMemo, useContext } from 'react';

import { TeamsPageContext } from '@vyce/core/src/contexts';

import { LegendInviteButton } from '../../../components';
import { useActions, useTypedSelector } from '../../../hooks';
import { WidgetModule } from '../../widgetModule';

export const useTeamsDashboardData = () => {
  const { dashboards, first_name } = useTypedSelector(state => state.user);
  const { teams, loading, selectedCompanyId = '' } = useContext(TeamsPageContext);
  const { updateUserDashboards } = useActions();

  const contextOptions = useMemo(
    () => ({
      dashboards,
      first_name,
      teams: teams ?? [],
      companyDataLoading: loading,
      selectedCompanyId,
      updateUserDashboards,
      widgetComponent: WidgetModule,
      inviteButtonComponent: LegendInviteButton,
    }),
    [dashboards, first_name, teams, loading, selectedCompanyId, updateUserDashboards]
  );
  return {
    contextOptions,
  };
};
