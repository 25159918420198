import {
  deleteInviteRequest,
  getFullInviteListRequest,
  resendInviteRequest,
} from '@vyce/core/src/api/legend/invites';

import { useTypedSelector } from '../../../hooks';

export const useModuleData = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return {
    selectedCompany,
    deleteInviteRequest,
    getFullInviteListRequest,
    resendInviteRequest,
  };
};
