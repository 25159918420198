import React from 'react';

import { AITool } from '@vyce/core/src/views/ai';

import { useTypedSelector } from '../../hooks';

export const LegendAITool: React.FC = () => {
  const { user } = useTypedSelector(state => state);

  return <AITool user={user} model="gpt-4o" />;
};
