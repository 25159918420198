import { axios } from '@vyce/core/src/api';

import { Tag } from '../../types';
import { CreateTagArgs, DeleteTagArgs, GetTagsArgs, UpdateTagArgs } from '../types';

export const fetchTagsRequest = ({ group = '', offset = 0, limit = 1000, short = true }: GetTagsArgs) => {
  return axios.get(`/admin/handbook/tags`, {
    params: { limit, offset, short, group: group },
  });
};

export const editTagRequest = ({ name, id, group, verified }: UpdateTagArgs) => {
  return axios.patch(`/admin/handbook/tags/${id}`, { name, group, verified });
};

export const createTagRequest = ({ name, group }: CreateTagArgs) => {
  return axios.post(`/admin/handbook/tags`, { name, group });
};

export const deleteTagRequest = ({ id }: DeleteTagArgs) => {
  return axios.delete(`/admin/handbook/tags/${id}`);
};

export const getTagsGroups = () => {
  return axios.get<{ groups: string[] }>('/admin/handbook/tags/groups');
};

export const postMergeTags = (data: { main_tag_id: string; tags: string[] }) => {
  return axios.post<{ groups: string[] }>('/admin/handbook/tags/merge', data);
};

export const getTagListRequest = ({ group, substring }: { group: string; substring?: string }) => {
  return axios.get<{ count: number; items: Tag[] }>(`admin/handbook/tags`, {
    params: {
      group,
      substring,
    },
  });
};
