import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { CommonNavBarBlock } from '@vyce/core/src/components';
import { PreviewButton } from '@vyce/core/src/views/preview/components/PreviewButton';
import { COMPANY_PROFILE_TABS } from '@vyce/core/src/views/profile/config';

import { RouteLegendCompanyProfileComponent } from './RouteLegendCompanyProfileComponent';

export const LegendCompanyProfile: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const goToPreview = () => {
    history.push(`/companies/${id}`);
  };

  return (
    <Box position="relative">
      <CommonNavBarBlock tabItems={COMPANY_PROFILE_TABS} />

      <PreviewButton goToPreview={goToPreview} />

      <Box padding={isMobile ? 1 : 5} paddingTop={isMobile ? 1 : 3}>
        <Switch>
          <Route component={RouteLegendCompanyProfileComponent} />
        </Switch>
      </Box>
    </Box>
  );
};
