import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  InputLabel,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { HiOutlineTrash } from 'react-icons/hi';

import { AppTextField, useAppTextFieldStyles } from '@vyce/core/src/components/inputs/AppTextField';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppIconButton } from '@vyce/core/src/components/AppIconButton';
import { CreatSurveyData } from '@vyce/core/src/api/types';
import {
  addQuestionRequest,
  createSurveyRequest,
  deleteQuestionRequest,
  getSurveyAnswers,
  updateSurveyRequest,
} from '@vyce/core/src/api/legend/surveys';
import { LegendSurvey, Question } from '@vyce/core/src/types';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import useStyles from '../../styles';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

interface Props {
  open: boolean;
  selectedSurveyId?: string;
  handleDialogClose: Function;
}

export const CreateSurveyDialog: React.FC<Props> = ({ open, handleDialogClose, selectedSurveyId }) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { isMobile } = useContext(DeviceContext);
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { access_token } = useTypedSelector(state => state.helper);
  const [name, setName] = useState<string>('');
  const [selectedSurvey, setSelectedSurvey] = useState<LegendSurvey | undefined>();
  const [newQuestion, setNewQuestion] = useState<string>('');
  const [questions, setQuestions] = useState<Question[]>([]);
  const inputClasses = useAppTextFieldStyles();

  const getSurvey = async (uuid: string) => {
    try {
      const res = await getSurveyAnswers(uuid);
      setSelectedSurvey(res.data);
    } catch (e) {
      handleServerError(e);
    }
  };

  const createSurvey = async () => {
    const data: CreatSurveyData = {
      name,
      questions,
    };
    try {
      await createSurveyRequest(access_token, data);
      showNotification({ message: 'Survey has been created', options: { variant: 'success' } });
      handleClose();
    } catch (e) {
      handleServerError(e);
    }
  };

  const addQuestionToSurvey = async () => {
    if (!newQuestion) {
      return;
    }
    if (!selectedSurveyId) {
      return addLocalQuestion();
    }
    try {
      const res = await addQuestionRequest(access_token, selectedSurveyId, { text: newQuestion });
      setSelectedSurvey(res.data);
      setNewQuestion('');
    } catch (e) {
      handleServerError(e);
    }
  };

  const updateSurvey = async () => {
    if (!selectedSurveyId) {
      return;
    }
    const data: CreatSurveyData = {
      name,
      questions,
    };
    try {
      await updateSurveyRequest(access_token, selectedSurveyId, data);
      showNotification({ message: 'Survey has been updated', options: { variant: 'success' } });
      handleClose();
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteQuestion = async (uuid?: string) => {
    if (!uuid || !selectedSurveyId) {
      return;
    }
    try {
      await deleteQuestionRequest(access_token, selectedSurveyId, uuid);
      setQuestions(value => value.filter(item => item.uuid !== uuid));
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteLocalQuestion = (index: number) => {
    setQuestions(value => {
      let arr = [...value];
      arr.splice(index, 1);
      return arr;
    });
  };

  useEffect(() => {
    if (selectedSurveyId) {
      getSurvey(selectedSurveyId);
    } else {
      setSelectedSurvey(undefined);
    }
  }, [selectedSurveyId]);

  useEffect(() => {
    if (selectedSurvey) {
      setName(selectedSurvey.name);
      setQuestions(selectedSurvey.questions);
    } else {
      setName('');
      setQuestions([]);
    }
  }, [selectedSurvey]);

  const addLocalQuestion = () => {
    if (newQuestion) {
      setQuestions(value => [...value, { text: newQuestion }]);
      setNewQuestion('');
    }
  };

  const handleClose = () => {
    handleDialogClose();
    setTimeout(() => {
      setSelectedSurvey(undefined);
      setQuestions([]);
    }, 1000);
  };

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      classes={{ paper: classes.createSurveyDialog }}
      onClose={handleClose}
      open={open}>
      <DialogTitle>{selectedSurveyId ? 'Update' : 'Create'} survey</DialogTitle>
      <DialogContent>
        {!!selectedSurveyId && (
          <DialogContentText>If you delete question we'll lose all user's answers.</DialogContentText>
        )}
        <AppTextField value={name} onChange={e => setName(e.target.value)} label="Survey name" fullWidth />

        <Box className={classes.questionsContainer}>
          {questions &&
            questions.map((question, index) => (
              <Box
                key={question.text + index}
                marginTop={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Typography>{question.text}</Typography>

                <AppIconButton
                  onClick={() =>
                    selectedSurveyId ? deleteQuestion(question.uuid) : deleteLocalQuestion(index)
                  }
                  variant="error">
                  <HiOutlineTrash color={theme.palette.error.dark} />
                </AppIconButton>
              </Box>
            ))}
        </Box>

        <FormControl fullWidth variant="filled" margin="normal">
          <InputLabel htmlFor="question">Question</InputLabel>
          <FilledInput
            id="question"
            disableUnderline
            classes={inputClasses}
            value={newQuestion}
            onChange={e => setNewQuestion(e.target.value)}
            endAdornment={
              <Button color="secondary" onClick={addQuestionToSurvey}>
                Add
              </Button>
            }
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={handleClose} color="primary" variant="outlined">
          Close
        </Button>
        <Button
          size="large"
          onClick={selectedSurvey ? updateSurvey : createSurvey}
          color="primary"
          variant="contained">
          {selectedSurvey ? 'Update' : 'Create'} Survey
        </Button>
      </DialogActions>
    </Dialog>
  );
};
