import { axios } from '@vyce/core/src/api';

import { GetCompanies } from '../types';
import { Company } from '../../types';

export const getCompaniesRequest = (token: string, data: GetCompanies) => {
  return axios.post(`/admin/companies/search`, data);
};

export const getCompaniesForManagementRequest = (data: GetCompanies) => {
  return axios.post(`/admin/companies/search_for_management`, data);
};

export const getCompanyRequest = (token: string, companyId: string) => {
  return axios.get(`/admin/companies/${companyId}?short=false`);
};

export const updateCompanyRequest = (token: string, companyId: string, data: Company) => {
  return axios.patch(`/admin/companies/${companyId}`, data);
};

export const saveLogoRequest = (file: File, token: string, companyId: string) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`/admin/companies/${companyId}/logo`, bodyFormData);
};
