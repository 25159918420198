import { createStyles, makeStyles, Theme } from '@material-ui/core';

const HEIGHT = 'calc(100vh - 146px)';
const MOBILE_HEIGHT = 'calc(100vh - 80px)';

export default makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      maxHeight: HEIGHT,
      [theme.breakpoints.down('xs')]: {
        maxHeight: MOBILE_HEIGHT,
      },
    },
    createTagButton: {
      width: '80px',
    },
    toolbarContainer: {
      padding: `${theme.spacing(1)}px !important`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    contentContainer: {
      marginTop: '40px',
    },
    createSurveyDialog: {
      width: '100%',
    },
    questionsContainer: {
      maxHeight: '50vh',
      overflowY: 'auto',
    },
    surveyPaper: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
    reportPaper: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    moduleContainer: {
      padding: '24px 40px',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
    filterWrapper: {
      marginRight: '40px',
      maxWidth: '320px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'unset',
        marginRight: 'unset',
        padding: '16px',
      },
    },
    tabsWrapper: {
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('md')]: {
        marginLeft: '-16px',
        marginRight: '-16px',
      },
    },
  })
);
